import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { formatDistanceToNowStrict } from "date-fns";
import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
// import mhz from '../assets/bid.png';

import { mn } from "date-fns/locale";
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
import erdem from "../assets/erdem.svg";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import axios from "axios";
import Comment from "./comment";
export const Article = () => {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const bairshil = location.pathname;

  const dataRetriever = async () => {
    try {
      console.log("dataRetriever called");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const response = await axios
        .get(`https://tmull-be.vercel.app/news/${bairshil.split("/")[2]}`)
        .then((res) => {
          document.title = `Tmull ・ ${res?.data?.data?.ti}`;
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
      console.log("API response:", response?.data);
      setData(response?.data?.data);
      setLoad(true);
      const metaTags = [
        {
          name: "description",
          content: data.di,
        },
        { property: "og:title", content: data.ti },
        {
          property: "og:description",
          content: data.di,
        },
        { property: "og:url", content: window.location.href },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
            data.img,
        },
      ];

      metaTags.forEach(({ name, property, content }) => {
        const selector = name
          ? `meta[name="${name}"]`
          : `meta[property="${property}"]`;
        let tag = document.querySelector(selector);

        if (!tag) {
          tag = document.createElement("meta");
          if (name) tag.setAttribute("name", name);
          if (property) tag.setAttribute("property", property);
          document.head.appendChild(tag);
        }

        tag.setAttribute("content", content);
      });
    } catch (error) {
      console.error("Error retrieving data:", error);
      window.location.href = "/";
    }
  };
  const newel = async (el) => {
    try {
      console.log("dataRetriever called");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      const response = await axios
        .get(`https://tmull-be.vercel.app/news/${el}`)
        .then((res) => {
          document.title = `Tmull ・ ${res?.data?.data?.ti}`;
          return res;
        })
        .catch((err) => {
          console.error(err);
        });
      console.log("API response:", response?.data);
      setData(response?.data?.data);
      setLoad(true);
    } catch (error) {
      console.error("Error retrieving data:", error);
      window.location.href = "/";
    }
  };
  const [top, setTop] = useState([]);
  useEffect(() => {
    if (bairshil) {
      dataRetriever();
    }
    const dataRetriever2 = async () => {
      await axios
        .get(`https://tmull-be.vercel.app/news?page=1&limit=3`)
        .then((response) => {
          console.log(response?.data?.data);
          setTop(response?.data?.data);
        });
    };
    dataRetriever2();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (bairshil) {
      dataRetriever();
    }
  }, [bairshil]);
  useEffect(() => {
    document.title = "Tmull ・ Мэдээ";
    if (data != []) {
      document.title = `Tmull ・ ${data.ti || "Мэдээ"}`;
    }
  }, [data]);
  const like = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/News/like/${cId}`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    }
  };
  const dlike = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/News/dlike/${cId}`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    }
  };
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check this out!",
          text: "hello",
          url: "yes",
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      alert("Share feature not supported on this browser.");
    }
  };
  return (
    <div className=" dark:text-white">
      {load ? (
        <div className="w-full flex flex-col justify-center items-center">
          {/* <Head>
            <meta
              property="og:title"
              content={data.ti || "'Tmull' боловсролын веб сайт"}
            />
            <meta
              property="og:description"
              content={data.di || "Tmull-тэй хамтдаа ирээдүйгээ бүтэцгээе!"}
            />
            <meta
              property="og:type"
              content="article"
              data-react-helmet="true"
            />
            <meta
              property="og:image"
              content={
                "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                data.img
              }
            />
            <meta
              property="og:url"
              content={
                "https://www.tmull.mn/" + data.pl || "https://www.tmull.mn"
              }
            />
          </Head> */}
          <Helmet>
            <meta property="og:title" content={data.ti} />
            <meta property="og:description" content={data.di} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:image"
              content={
                "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                data.img
              }
            />
            <meta property="og:site_name" content="Tmull.mn" />
          </Helmet>
          <div className="flex flex-row justify-center w-full ">
            <div className="w-full absolute overflow-visible lg:overflow-auto h-full">
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                  data.img
                }
                className="lg:w-screen w-full absolute left-0 h-[400px] md:h-[600px] xl:h-[700px] 2xl:h-[900px] object-cover brightness-[0.4] dark:opacity-80 transition-all ease-in-out duration-500"
                alt=""
              />
              <div class="w-full absolute bg-gradient-to-t from-white dark:from-black via-transparent to-transparent h-[400px] md:h-[600px] xl:h-[700px] 2xl:h-[900px] transition-all ease-in-out duration-500"></div>
            </div>
          </div>
          <div className="w-full">
            <div className="relative mt-28 lg:mt-48 transition-all ease-in-out duration-50">
              <div className="flex text-white gap-2 drop-shadow-[0_3px_30px_rgba(0,0,0,1)]">
                <div className="flex gap-1 items-center">
                  <svg
                    className="h-6 fill-white"
                    viewBox="0 0 512 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                    />
                  </svg>
                  {data.s}
                </div>{" "}
                |<div className="oflex items-center uppercase">{data.rt}</div>
              </div>
              <div className="text-xs lg:text-sm mt-2 lg:mt-4 text-white drop-shadow-[0_3px_30px_rgba(0,0,0,1)]">
                {data.ht.length > 0 ? (
                  <div className="w-full capitalize flex gap-2 overflow-x-auto items-center cursor-default">
                    {data.ht.map((el) => (
                      <div className="p-1 px-3 rounded-lg cursor-pointer bg-white/25 border border-white/50">
                        #{el}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-semibold mt-2 lg:mt-4 text-white drop-shadow-[0_3px_30px_rgba(0,0,0,1)]">
                {data.ti}
              </div>
              <div className="text-sm md:text-base lg:text-lg opacity-85 mt-2 lg:mt-4 text-white drop-shadow-[0_3px_30px_rgba(0,0,0,1)]">
                {data.di}
              </div>{" "}
              <div className="w-full flex justify-center  absolute opacity-100 md:opacity-0 transition-all ease-in-out duration-50 my-8 lg:mt-16">
                <div className="h-96 bg-white dark:hidden rounded-xl absolute w-screen md:w-full transition-all ease-in-out duration-50 top-0"></div>
              </div>
              <div className="rounded-xl md:dark:bg-[#121212] dark:bg-transparent transition-all ease-in-out duration-50 bg-white md:bg-[#fafafa] md:p-4 lg:p-8 dark:border-neutral-700 w-full my-8 lg:mt-16 lg:gap-12 xl:gap-16 gap-6 flex md:flex-row flex-col">
                <div className="w-full md:w-[70%] z-10 pt-4 md:pt-0">
                  <div className="w-full flex gap-2">
                    <div className="flex flex-row justify-between items-start text-md lg:text-base w-full flex-wrap gap-2">
                      <div className="flex gap-2">
                        <div className=" text-nowrap relative lg:px-2 lg:text-lg px-1 flex justify-center items-center flex-col">
                          <div
                            className="flex justify-end cursor-pointer"
                            style={{
                              color: data.like.includes(
                                localStorage.getItem("user")
                              )
                                ? "green"
                                : "none",
                            }}
                            onClick={() => like(data._id)}
                          >
                            {data.like.length} ↑
                          </div>
                          <div
                            className="flex justify-end cursor-pointer"
                            style={{
                              color: data.dlike.includes(
                                localStorage.getItem("user")
                              )
                                ? "red"
                                : "none",
                            }}
                            onClick={() => dlike(data._id)}
                          >
                            {data.dlike.length} ↓
                          </div>
                        </div>
                        <div className="gap-1 flex flex-wrap lg:mt-1 text-sm lg:text-base">
                          <div className="opacity-80 flex">Нийтлэгч</div>
                          {data.au.map((el) => {
                            return (
                              <div className="hover:underline cursor-pointer flex">
                                {el}
                              </div>
                            );
                          })}
                          <div className="opacity-80 flex gap-1">
                            {" • "}
                            <div className="capitalize">
                              {formatDistanceToNowStrict(new Date(data.cAt), {
                                addSuffix: true,
                                locale: mn,
                              })}
                            </div>
                          </div>
                          <div className="opacity-80 flex gap-1 ">
                            {" • "}

                            {data.ca.length > 0 ? (
                              <div className="capitalize flex flex-row hover:gap-2 group/item">
                                <div className="w-0 opacity-0 group-hover/item:opacity-100 cursor-default group-hover/item:w-16">
                                  Ангилал:{" "}
                                </div>
                                <div className="cursor-pointer w-max">
                                  {data.ca.map((el) => el).join(", ")}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="opacity-80 flex gap-1">
                            {" • "}
                            {data.cou.length > 0 ? (
                              <div className="capitalize flex flex-row hover:gap-2 group/item">
                                <div className="w-0 group-hover/item:w-7 opacity-0 group-hover/item:opacity-100 cursor-default">
                                  Улс:{" "}
                                </div>
                                <div className="cursor-pointer w-max">
                                  {data.cou.map((el) => el).join(", ")}
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row gap-2">
                        <div className="hover:bg-black/10 w-8 h-8 flex justify-center items-center rounded p-2 group/item dark:hover:bg-white/10 transition-all duration-300">
                          <div className="absolute group-hover/item:visible justify-center flex flex-col items-center invisible text-sm font-medium dark:text-white transition-all duration-300 shadow-xs opacity-0 group-hover/item:opacity-100 pointer-events-none">
                            <div className="bg-[#E1E1E0] z-10 px-3 py-2 rounded-lg dark:bg-[#32302F]">
                              Share on facebook
                            </div>
                            <svg
                              viewBox="0 0 34 18"
                              fill="none"
                              className="mb-20 h-2"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 0H34L17 18L0 0Z"
                                className="fill-black/10 dark:fill-white/10"
                              />
                            </svg>
                          </div>

                          <svg
                            width="8"
                            height="19"
                            viewBox="0 0 8 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_519_2977)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.135 3H8V0H6.135C5.03688 0.0013232 3.98411 0.438136 3.20763 1.21463C2.43114 1.99111 1.99432 3.04388 1.993 4.142V6H0V9H2V18.938H5V9H7.021L7.613 6H5V3.591C5.00233 3.43481 5.06548 3.28569 5.17603 3.17532C5.28658 3.06496 5.43581 3.00207 5.592 3H6.135Z"
                                className="fill-black/75 dark:fill-white/75"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_519_2977">
                                <rect width="8" height="19" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="hover:bg-black/10 w-8 h-8 flex justify-center items-center rounded p-1 group/item dark:hover:bg-white/10 transition-all duration-300">
                          <div className="absolute group-hover/item:visible justify-center flex flex-col items-center invisible text-sm font-medium dark:text-white transition-all duration-300 shadow-xs opacity-0 group-hover/item:opacity-100 pointer-events-none">
                            <div className="bg-[#E1E1E0] z-10 px-3 py-2 rounded-lg dark:bg-[#32302F]">
                              Share link
                            </div>
                            <svg
                              viewBox="0 0 34 18"
                              fill="none"
                              className="mb-20 h-2"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 0H34L17 18L0 0Z"
                                className="fill-black/10 dark:fill-white/10"
                              />
                            </svg>
                          </div>
                          <svg
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.0126 7.96173C10.3527 7.30228 9.458 6.93184 8.5251 6.93184C7.5922 6.93184 6.69747 7.30228 6.0376 7.96173L2.4836 11.5157C1.8337 12.1775 1.47143 13.0691 1.47562 13.9966C1.47981 14.9242 1.85013 15.8125 2.50598 16.4683C3.16184 17.1242 4.05017 17.4945 4.97768 17.4987C5.9052 17.5029 6.79684 17.1406 7.4586 16.4907L7.9196 16.0307M7.4586 11.5157C8.11834 12.1754 9.01311 12.546 9.9461 12.546C10.8791 12.546 11.7739 12.1754 12.4336 11.5157L15.9866 7.96173C16.6281 7.2984 16.9833 6.40958 16.9756 5.48682C16.968 4.56407 16.5981 3.68127 15.9456 3.0287C15.2932 2.37612 14.4105 2.00603 13.4877 1.99817C12.5649 1.99032 11.676 2.34535 11.0126 2.98673L10.2996 3.69973"
                              className="stroke-black/75 dark:stroke-white/75"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="hover:bg-black/10 w-8 h-8 flex justify-center items-center rounded p-1 group/item dark:hover:bg-white/10 transition-all duration-300">
                          <div className="absolute group-hover/item:visible justify-center flex flex-col items-center invisible text-sm font-medium dark:text-white transition-all duration-300 shadow-xs opacity-0 group-hover/item:opacity-100 pointer-events-none">
                            <div className="bg-[#E1E1E0] z-10 px-3 py-2 rounded-lg dark:bg-[#32302F]">
                              Share this article
                            </div>
                            <svg
                              viewBox="0 0 34 18"
                              fill="none"
                              className="mb-20 h-2"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 0H34L17 18L0 0Z"
                                className="fill-black/10 dark:fill-white/10"
                              />
                            </svg>
                          </div>

                          <svg
                            width="14"
                            height="20"
                            viewBox="0 0 14 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_519_2983)">
                              <path
                                d="M13 19L7 14L1 19V2C1 1.73478 1.10536 1.48043 1.29289 1.29289C1.48043 1.10536 1.73478 1 2 1H12C12.2652 1 12.5196 1.10536 12.7071 1.29289C12.8946 1.48043 13 1.73478 13 2V19Z"
                                className="stroke-black/75 dark:stroke-white/75"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_519_2983">
                                <rect width="14" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="hover:bg-black/10 w-8 h-8 flex justify-center items-center rounded p-1 group/item dark:hover:bg-white/10 transition-all duration-300">
                          <div className="absolute group-hover/item:visible justify-center flex flex-col items-center invisible text-sm font-medium dark:text-white transition-all duration-300 shadow-xs opacity-0 group-hover/item:opacity-100 pointer-events-none">
                            <div className="bg-[#E1E1E0] z-10 px-3 py-2 rounded-lg dark:bg-[#32302F]">
                              More
                            </div>
                            <svg
                              viewBox="0 0 34 18"
                              fill="none"
                              className="mb-20 h-2"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 0H34L17 18L0 0Z"
                                className="fill-black/10 dark:fill-white/10"
                              />
                            </svg>
                          </div>
                          <svg
                            width="16"
                            height="3"
                            viewBox="0 0 16 3"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_519_2986)">
                              <path
                                d="M2 0C2.39782 0 2.77936 0.158035 3.06066 0.43934C3.34196 0.720644 3.5 1.10218 3.5 1.5C3.5 1.89782 3.34196 2.27936 3.06066 2.56066C2.77936 2.84196 2.39782 3 2 3C1.60218 3 1.22064 2.84196 0.93934 2.56066C0.658035 2.27936 0.5 1.89782 0.5 1.5C0.5 1.10218 0.658035 0.720644 0.93934 0.43934C1.22064 0.158035 1.60218 0 2 0ZM8.041 0C8.43883 0 8.82036 0.158035 9.10166 0.43934C9.38296 0.720644 9.541 1.10218 9.541 1.5C9.541 1.89782 9.38296 2.27936 9.10166 2.56066C8.82036 2.84196 8.43883 3 8.041 3C7.64318 3 7.26164 2.84196 6.98034 2.56066C6.69904 2.27936 6.541 1.89782 6.541 1.5C6.541 1.10218 6.69904 0.720644 6.98034 0.43934C7.26164 0.158035 7.64318 0 8.041 0ZM14 0C14.3978 0 14.7794 0.158035 15.0607 0.43934C15.342 0.720644 15.5 1.10218 15.5 1.5C15.5 1.89782 15.342 2.27936 15.0607 2.56066C14.7794 2.84196 14.3978 3 14 3C13.6022 3 13.2206 2.84196 12.9393 2.56066C12.658 2.27936 12.5 1.89782 12.5 1.5C12.5 1.10218 12.658 0.720644 12.9393 0.43934C13.2206 0.158035 13.6022 0 14 0Z"
                                className="fill-black/75 dark:fill-white/75"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_519_2986">
                                <rect width="16" height="3" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex items-center justify-center mt-8 relative w-full text-justify font-normal flex-col text-sm lg:text-base xl:text-lg"
                    dangerouslySetInnerHTML={{
                      __html: data.con
                        .replace(/className=/g, "class=")
                        .replace(/classname/g, "class"),
                    }}
                  ></div>
                  <Comment className="w-full" id={data._id} />
                </div>
                <div className="w-full md:w-[25%]">
                  <div className="gap-2.5 flex flex-col sticky top-28">
                    <div>
                      <div className="font-semibold text-sm">
                        TMULL NEWS NOTIFICATION
                      </div>
                      <div className="text-xs opacity-75">
                        Шинэ мэдээ мэдээллээс хоцрохгүй байхыг хүсч байна уу?
                        Бид таны имэйлд хамгийн хэрэгтэй, онцлох мэдээнүүдийг
                        цаг алдалгүй, тогтмол хүргэх болно. TMULL танд амжилтанд
                        хүрэхэд тусална!
                      </div>
                    </div>

                    <div class="relative">
                      <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                        <svg
                          class="w-4 h-4 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 16"
                        >
                          <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                          <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="input-group-1"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full ps-10 p-2.5  dark:bg-stone-800 dark:border-stone-700 dark:placeholder-gray-400 dark:text-white"
                        placeholder="name@gmail.com"
                      />
                    </div>
                    <button className="w-full p-2 px-1 bg-[#4BAB4D] text-white rounded-lg">
                      Subscribe
                    </button>
                    <div>
                      <div className="flex flex-col gap-3">
                        <div className="font-semibold text-sm mt-4">
                          ШИНЭ МЭДЭЭ
                        </div>
                        {top.map((el) => {
                          return (
                            <Link
                              to={"/niitleluud/" + el.pl}
                              onClick={() => {
                                setLoad(false);
                                setData([]);
                                newel(el.pl);
                              }}
                              className="flex gap-3"
                            >
                              <img
                                src={
                                  "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                                  el.img
                                }
                                className="h-16 xl:h-20 2xl:h-28 aspect-square bg-black rounded-md object-cover"
                              />
                              <div className="flex flex-col justify-between">
                                <div>
                                  <div className="font-semibold text-xs lg:text-sm">
                                    {el.ti}
                                  </div>
                                  <div className="text-xs max-h-8 xl:max-h-12 overflow-hidden">
                                    {el.di}
                                  </div>
                                </div>
                                <div className="text-xs hover:underline text-[#4BAB4D]">
                                  {el.rt} унших
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="animate-pulse text-justify xl:text-lg w-full lg:w-4/5 xl:w-3/5">
            <div>
              <div className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl">
                <div class="h-14 w-full bg-black/25 dark:bg-white/25 rounded"></div>
              </div>
              <div className="flex flex-row justify-between md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl mt-2 ">
                <div className="gap-1.5 flex flex-row">
                  <div class="h-6 w-64 bg-black/25 dark:bg-white/25 rounded"></div>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-2">
                <div className="md:text-lg xl:text-xl">
                  <div class="h-6 w-32 bg-black/25 dark:bg-white/25 rounded"></div>
                </div>
                <div className="h-full flex flex-row gap-2 lg:gap-4">
                  <div class="h-6 w-6 bg-black/25 dark:bg-white/25 rounded"></div>

                  <div class="h-6 w-6 bg-black/25 dark:bg-white/25 rounded"></div>
                  <div class="h-6 w-6 bg-black/25 dark:bg-white/25 rounded"></div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center">
              <div className="mt-2 w-full relative md:mt-4 lg:mt-4 overflow-hidden xl:mt-6 2xl:mt-6 h-[350px] md:h-[400px] xl:h-[450px] 2xl:h-[500px]">
                <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-10"></div>
                <div className="absolute flex flex-row items-center justify-center gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50">
                  <svg
                    className="h-5 dark:fill-white fill-black"
                    viewBox="0 0 512 512"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                    />
                  </svg>
                  <div className="w-8"></div>
                </div>
                <div className="w-full h-full object-cover bg-black/25 dark:bg-white/25"></div>
              </div>
            </div>
            <div class="h-4 w-[80%] bg-black/25 dark:bg-white/25 rounded mt-2"></div>
            <div className="mt-16 gap-4 flex flex-col">
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-2"></div>
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-1"></div>
              </div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-1"></div>
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-2"></div>
              </div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="grid grid-cols-3 gap-4 w-full">
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-2"></div>
                <div class="h-4 bg-black/25 dark:bg-white/25 rounded col-span-1"></div>
              </div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
              <div class="h-4 bg-black/25 dark:bg-white/25 rounded w-full"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
