import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useContext } from "react";
import axios from "axios";
export const DailyTip = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Tmull ・ Зөвлөгөө";
    const tip = async () => {
      await axios
        .get(`https://tmull-be.vercel.app/tipscategories`)
        .then((response) => {
          setCategories(response?.data?.categories);
          console.log(response?.data?.categories);

          setLoading(false);
        });
    };
    tip();
  }, []);
  const categoryDescriptions = {
    "Extracurricular Activities":
      "Хичээлээс гадуур үйл ажиллагаанд оролцсоноор таны хувийн хөгжлийг нэмэгдүүлж, танилын хүрээгээ тэлэх, ур чадвараа сайжруулах боломжтой. Клуб, спорт, сайн дурын ажил эсвэл дадлага хийснээр таны CV илүү баялаг болно.",
    Motivation:
      "Зорилгодоо хүрэхийн тулд урам зориг, тэвчээрийг хадгалж, амжилт руу яв. Энэ хэсэгт бид таныг урамшуулах олон арга, стратеги, ижил зорилготой хүмүүсийн туршлагыг хуваалцах болно.",
    "Quote, Ишлэл":
      "Таны сэтгэл санааг өргөж, урам зориг өгч чадах хүчирхэг ишлэлүүдийг эндээс олоорой. Сайн дурын хүмүүсийн амжилттай амьдралын талаархи үгс танд итгэл, хүсэл эрмэлзэл төрүүлэх болно.",
    "University Prep":
      "Их сургуульд суралцах бэлтгэлээ хангахад хэрэгтэй бүх зөвлөгөө, мэдээллийг энэ хэсгээс олоорой. Тэтгэлэг авах, шалгалтад бэлдэх, эсээ бичих болон сургуулийн амьдралд хэрхэн бэлтгэх талаар хэрэгтэй зөвлөмжүүдийг эндээс авна.",
    "Асран хамгаалагч":
      "Хүүхдийн асаргаа, харилцааны ур чадвар болон тэдний сэтгэлзүйн дэмжлэгийг хэрхэн үзүүлэх талаар хэрэгтэй зөвлөмжүүдийг эндээс олоорой. Хүүхдийнхээ хэрэгцээг ойлгон, тэднийг хөгжүүлэх арга замыг судлаарай.",
    "Мэдсэн ч болоосой":
      "Энэ хэсэгт та амьдралынхаа алдаануудыг ойлгон, ирээдүйд тэдгээрийг хэрхэн засаж болох талаархи зөвлөмжүүдийг олж мэдэх боломжтой. Өнгөрсөн туршлагаас суралцаж, илүү амжилттай амьдрах арга замыг хайж олоорой.",
    Мэргэжил:
      "Мэргэжлийн чиглэлд өөрийнхөө хөгжлийг хэрхэн дэмжих, ажил мэргэжлээ төлөвлөх, ур чадвараа сайжруулах зөвлөмжүүдийг эндээс олоорой. Мэргэжлээ сонгох, карьерийн зорилгоо тодорхойлох хэрэгтэй бүх мэдээллийг бид танд хүргэнэ.",
    "Сэтгэлзүй, Burn-Out":
      "Сэтгэл санаа болон стрессийн менежментийн тухай мэдлэгийг олж аваарай. Түүхий эдийн стрессийг хэрхэн удирдах, амжилттай ажиллах чадварыг хадгалах зөвлөмжүүдийг энэ хэсгээс авна.",
    "Хэл сурах":
      "Хэл сурахад зориулагдсан хэрэгтэй хэрэгслүүд, аппликейшнүүд, сурах стратегиудыг эндээс олоорой. Хэлний мэдлэгээ хурдан сайжруулах, шинэ үгсийг цээжлэх болон үр дүнтэй сурах арга замыг эндээс судлаарай.",
    Шалгалт:
      "Шалгалтын бэлтгэлийг хэрхэн зөв хийх, анхаарал төвлөрүүлэх, стрессийг хэрхэн удирдах зөвлөмжүүдийг эндээс олж авах боломжтой. Шалгалтын үед зөв арга барилаар бэлтгэж, амжилттай тэнцэхэд хэрэгтэй бүх зөвлөмжүүдийг энэ хэсэгт багтаасан.",
  };
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="text-2xl font-semibold text-center pb-4 w-full">
        Өдөр тутмын зөвлөгөөний ангилал
      </div>

      <div class="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full">
        {categories?.map((category, index) => {
          const description =
            categoryDescriptions[category] ||
            "Энэ категориор тодорхой мэдээлэл байхгүй байна."; // Default description if category not found

          return (
            <Link
              className="rounded-lg p-4 border-2 group/item hover:border-[#4BAB4D] hover:bg-[#4BAB4D10] drop-shadow-sm bg-white/10 cursor-pointer"
              key={index}
              to={category}
            >
              <h3 className="group-hover/item:text-[#4BAB4D] font-semibold">
                {category}
              </h3>
              <p className="opacity-60 text-sm">{description}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};
