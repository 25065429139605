import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";

import axios from "axios";
import academy from "../assets/academy.svg";
import Comment from "./comment";
export const Academy1 = () => {
  const mergejil = [];
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [hex, setHex] = useState("");

  const location = useLocation();
  const bairshil = location.pathname;
  const dataRetriever = async () => {
    try {
      const response = await axios.get(
        `https://tmull-be.vercel.app/acad/${bairshil.split("/")[3]}`
      );

      if (response.status) {
        console.log(response?.data);
        setData(response?.data.data);
        setLoad(true);
        // color();
      }
    } catch (error) {
      console.error("Error retrieving data:", error);
      window.location.href = "/";
    }
  };
  useEffect(() => {
    document.title = "Tmull ・ Бэлдэхэд туслах сургалтууд";
    // const favicon = document.getElementById("favicon");

    // favicon.setAttribute("href", academy);

    dataRetriever();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  // const color = async () => {
  // 	await axios({
  // 		url: `https://tmull-be.vercel.app/color/${bairshil.split('/')[3]}`,
  // 		method: 'get',
  // 	}).then(response => {
  // 		setHex(response?.data);
  // 	});
  // };
  const like = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Acad/${cId}/like`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    } else {
      window.location.href = "/";
    }
  };
  const dlike = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Acad/${cId}/dlike`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    } else {
      window.location.href = "/nevtreh";
    }
  };
  return (
    <div>
      {load ? (
        <div className="mt-24 lg:mt-28 dark:text-white">
          <div className="flex justify-center">
            <div className="lg:w-[60%] text-justify">
              <div className="flex flex-col gap-2 justify-center items-center">
                <div className="flex flex-col justify-center items-center w-full mb-8 z-10 text-center">
                  <div className="font-bold text-xl lg:text-6xl">{data.na}</div>
                  <div className="font-normal text-md lg:text-lg text-[#3f3f3f] dark:text-[#bbbbbb]">
                    {data.ty}
                  </div>
                </div>
                <div className="w-full rounded-lg flex justify-center items-center cursor-pointer transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="relative group/item w-full h-80 flex justify-center items-center lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                    <img
                      alt=""
                      className="w-full h-full absolute mt-8 blur-2xl group-hover/item:blur-3xl opacity-50 group-hover/item:opacity-100 transition-all ease-in group-hover/item:ease-out duration-1000 delay-150 transform"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Acad%2F" +
                        data.img
                      }
                    />
                    <img
                      className="absolute w-full h-80 md:border-2 dark:border-zinc-800 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Acad%2F" +
                        data.img
                      }
                      id="i"
                      alt=""
                    />
                    <div className="flex flex-row text-sm absolute right-0 top-0 m-4 dark:bg-black/70 bg-white/70 p-2 rounded transition-all ease-in-out duration-300 delay-700 transform">
                      {data.ca.join(", ")}
                    </div>
                    <div className="flex flex-row text-sm absolute right-0 bottom-0 m-4 dark:bg-black/70 bg-white/70 py-2 pr-2 rounded transition-all ease-in-out duration-300 delay-700 transform">
                      <div className="flex items-center flex-row justify-center">
                        <svg
                          className="h-4 lg:h-5 px-2 hover:fill-green-700 cursor-pointer overflow-visible"
                          viewBox="0 0 311 282"
                          style={{
                            fill: data.like.includes(
                              localStorage.getItem("user")
                            )
                              ? "green"
                              : "none",
                          }}
                          onClick={() => like(data._id)}
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
                            className="dark:stroke-white stroke-black transition-all ease-in-out duration-1000 delay-1000 transform"
                            stroke-width="20"
                          />
                        </svg>
                        <div>{data.like.length}</div>
                      </div>
                      <div className="flex items-center flex-row justify-center">
                        <svg
                          className="h-4 lg:h-5 px-2 hover:fill-red-700 cursor-pointer overflow-visible"
                          onClick={() => dlike(data._id)}
                          style={{
                            fill: data.dlike.includes(
                              localStorage.getItem("user")
                            )
                              ? "red"
                              : "none",
                          }}
                          viewBox="0 0 311 282"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
                            className="dark:stroke-white stroke-black transition-all ease-in-out duration-1000 delay-1000 transform"
                            stroke-width="20"
                          />
                        </svg>
                        <div>{data.dlike.length}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="mt-8">
                <br />
                <strong>
                  Танилцуулга:
                  <br />
                </strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.di.replace(/\s*\/N\s*/g, `</br>`),
                  }}
                ></div>
              </p>
              <p styleName="padding-left: 30px;">
                <br />
                <strong>
                  Хөтөлбөр:
                  <br />
                </strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.pr.replace(/\s*\/N\s*/g, `</br>`),
                  }}
                ></div>
              </p>
              {data.sch === "-" ? (
                <></>
              ) : (
                <p styleName="padding-left: 30px;">
                  <br />
                  <strong>
                    📅Хөтөлбөрийн хугацаа:
                    <br />
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.sch.replace(/\s*\/N\s*/g, `</br>`),
                    }}
                  ></div>
                </p>
              )}
              {data.mo === "-" ? (
                <></>
              ) : (
                <p styleName="padding-left: 30px;">
                  <br />
                  <strong>
                    💸Төлбөр:
                    <br />
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.mo.replace(/\s*\/N\s*/g, `</br>`),
                    }}
                  ></div>
                </p>
              )}
              {data.pl === "-" ? (
                <></>
              ) : (
                <p styleName="padding-left: 30px;">
                  <br />
                  <strong>
                    Нэмэлт мэдээлэл:
                    <br />
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.pl.replace(/\s*\/N\s*/g, `</br>`),
                    }}
                  ></div>
                </p>
              )}
              <p styleName="padding-left: 30px;">
                <br />
                <strong>
                  📱Холбогдох:
                  <br />
                </strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.con.replace(/\s*\/N\s*/g, `</br>`),
                  }}
                ></div>
              </p>
              <p styleName="padding-left: 30px;">
                <br />
                <strong>
                  📍Хаяг:
                  <br />
                </strong>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.lo.replace(/\s*\/N\s*/g, `</br>`),
                  }}
                ></div>
              </p>
              {data.age === "-" ? (
                <></>
              ) : (
                <p styleName="padding-left: 30px;">
                  <br />
                  <strong>
                    ⏳Хамрах хүрээ:
                    <br />
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.age.replace(/\s*\/N\s*/g, `</br>`),
                    }}
                  ></div>
                </p>
              )}
            </div>
          </div>

          <Comment id={data._id} />
        </div>
      ) : (
        <div className="mt-24 lg:mt-28 dark:text-white">
          <div className="flex justify-center">
            <div className="lg:w-[60%] text-justify animate-pulse">
              <div className="flex flex-col gap-2 justify-center items-center">
                <div className="w-full rounded-lg flex justify-center items-center cursor-pointer transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="relative group/item w-full h-80 flex justify-center items-center lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                    <div className="w-full dark:bg-white/20 bg-black/10 h-full absolute blur-2xl group-hover/item:blur-3xl opacity-50 group-hover/item:opacity-100 transition-all ease-in group-hover/item:ease-out duration-1000 delay-150 transform"></div>
                    <div className="absolute dark:bg-white/20 bg-black/10 w-full h-80  lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform"></div>

                    <div className="flex w-16 h-8 flex-row text-sm absolute right-0 top-0 m-4 dark:bg-black/30 bg-white/30 p-2 rounded transition-all ease-in-out duration-300 delay-700 transform"></div>
                    <div className="flex flex-row text-sm absolute right-0 bottom-0 m-4 dark:bg-black/30 bg-white/30 py-2 pr-2 rounded transition-all ease-in-out duration-300 delay-700 transform">
                      <div className="flex items-center flex-row justify-center">
                        <svg
                          className="h-4 lg:h-5 px-2 hover:fill-green-700 fill-none cursor-pointer overflow-visible"
                          viewBox="0 0 311 282"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
                            className="dark:stroke-white stroke-black transition-all ease-in-out duration-1000 delay-1000 transform"
                            stroke-width="20"
                          />
                        </svg>
                        <div>5</div>
                      </div>
                      <div className="flex items-center flex-row justify-center">
                        <svg
                          className="h-4 lg:h-5 px-2 hover:fill-red-700 fill-none cursor-pointer overflow-visible"
                          viewBox="0 0 311 282"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
                            className="dark:stroke-white stroke-black transition-all ease-in-out duration-1000 delay-1000 transform"
                            stroke-width="20"
                          />
                        </svg>
                        <div>5</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mt-8 z-10">
                  <div className="font-bold text-xl lg:text-2xl dark:text-[#bbbbbb] dark:bg-white/20 bg-black/10 h-7"></div>
                  <div className="font-normal text-md lg:text-lg text-[#3f3f3f] dark:text-[#bbbbbb] dark:bg-white/20 bg-black/10 h-4 mt-2"></div>
                </div>
              </div>
              <p styleName="padding-left: 30px;">
                <br />
                <div className="dark:bg-white/20 bg-black/10 h-4 w-24"></div>
                <div className="dark:bg-white/20 bg-black/10 h-16 w-full mt-2"></div>
              </p>
              <p styleName="padding-left: 30px;">
                <br />
                <div className="dark:bg-white/20 bg-black/10 h-4 w-24"></div>
                <div className="dark:bg-white/20 bg-black/10 h-16 w-full mt-2"></div>
                <div className="dark:bg-white/20 bg-black/10 h-10 w-full mt-2"></div>
                <div className="dark:bg-white/20 bg-black/10 h-24 w-full mt-2"></div>
              </p>
              <p styleName="padding-left: 30px;">
                <br />
                <div className="dark:bg-white/20 bg-black/10 h-4 w-24"></div>
                <div className="dark:bg-white/20 bg-black/10 h-16 w-full mt-2"></div>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
