import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import datas from "../assets/data.svg";
import axios from "axios";
export const DataSet = () => {
  const mergejil = [];
  const [data, setData] = useState([]);

  useEffect(() => {
    document.title = "Tmull ・ Aрга хэмжээ болон сайн дурын үйл ажиллагаанууд";
    // const favicon = document.getElementById("favicon");
    // favicon.setAttribute("href", datas);
    const dataRetriever = async () => {
      await axios.get(`https://tmull-be.vercel.app/Sch`).then((response) => {
        console.log(response?.data?.data);
        setData(response?.data?.data);
      });
    };
    dataRetriever();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    document.title = "Tmull ・ Сургуулиудын Мэдээлэл";
  }, []);
  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="flex flex-col items-center justify-center my-16 ">
        <div className="font-semibold flex flex-col items-center justify-center mb-4">
          <div
            onClick={() => {
              window.location.pathname = "/noots";
            }}
            className="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform"
          >
            Сургуулиудын <br /> Мэдээлэл
          </div>
        </div>
        <div className=" flex flex-row scroll-smooth overflow-x-auto w-full gap-4">
          {data.map((el) => (
            <div
              onClick={() => {
                window.location.pathname = `/noots/sur_dataset/${el._id}`;
              }}
              className="group/item "
            >
              <div className="group-hover/item:rounded-3xl rounded-full overflow-hidden transition-all ease-in-out duration-700 delay-0 lg:group-hover/item:mx-8">
                <div className="w-80 lg:w-96  flex flex-col cursor-pointer justify-center items-center">
                  <div className="relative w-80 h-80 lg:w-96 lg:h-96 flex ">
                    <img
                      className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover brightness-50 group-hover/item:brightness-100 transition-all ease-in-out duration-1000 delay-0"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F" +
                        el.img
                      }
                      alt=""
                    />
                    <div className="bg-gradient-to-t from-[#000000] to-[#00000000] h-[60%] w-full absolute bottom-0"></div>
                    <div className="absolute text-white font-semibold pt-10 text-lg lg:text-xl w-full justify-center items-center flex group-hover/item:drop-shadow-lg group-hover/item:pt-4 transition-all ease-in-out duration-1000 delay-0 transform">
                      <div className="backdrop-blur-sm group-hover/item:bg-[#00000010] group-hover/item:px-2 group-hover/item:rounded-lg transition-all ease-in-out duration-1000 delay-0 transform">
                        RD: {el.appl[3].RD[0].deadline}
                      </div>
                    </div>
                    <div className="text-white font-semibold p-8 text-xl md:text-2xl lg:text-3xl flex flex-row w-full h-full justify-between transition-all items-center ease-in-out duration-1000 delay-0 transform group-hover/item:mt-[30%] ">
                      <div className="w-[70%]">{el.Uname}</div>
                      <div className="text-sm lg:text-base flex items-center flex-col right-0 justify-center">
                        <div class="text-yellow-400">
                          <svg
                            class="flex-shrink-0 size-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </div>
                        <div>5</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="space-y-3 font-semibold flex justify-center flex-col items-center w-full mt-16">
        <div className="font-bold text-xl md:text-2xl lg:text-3xl">
          Бүх сургуулиуд
        </div>
        <div className="flex flex-wrap gap-4 w-[80%] justify-center xl:text-base text-sm">
          {data.map((el) => (
            <div
              onClick={() => {
                window.location.pathname = `/noots/sur_dataset/${el._id}`;
              }}
              className="space-y-1 cursor-pointer flex flex-col justify-center w-32 xl:w-48 items-center"
            >
              <img
                className=" object-cover w-32 h-32 xl:w-48 xl:h-48 border-2 dark:border-neutral-700 rounded-xl bg-white"
                src={
                  "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F" +
                  el.logo
                }
                alt=""
              />
              <div className="truncate w-full">{el.Uname}</div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="w-full mt-16 flex justify-center items-center flex-col rounded-lg bg-[#00000028] dark:bg-black py-2">
				<div className="font-semibold flex flex-row items-center justify-center p-2 lg:w-[60%] text-center">
					<div className="text-xl md:text-2xl lg:text-3xl">
						Сургуулиудын DataSet-тэй холбоотой мэдээллүүд
					</div>
				</div>
				<div className="flex flex-row overflow-x-auto gap-4 p-2 w-full">
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
					<div>
						<div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative ring-2 ring-black m-2 bg-white">
							<img
								className="w-80 h-60 lg:w-96 lg:h-72 object-cover rounded-lg absolute"
								src={gh}
								alt=""
							/>
							<div className="absolute bottom-0 p-2 w-full  rounded-lg">
								<div className="font-bold text-xl md:text-2xl">
									Title/Heading
								</div>
								<div className="font-normal text-md lg:text-lg text-[#7c7c7c]">
									Date | Author
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
    </div>
  );
};
