import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/zurg.png";
import bt from "../assets/bt.jpg";
import more from "../assets/more.svg";

// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
import topik from "../assets/topikk.png";
import axios from "axios";
import Goethe from "../assets/goethee.png";
import jlpt from "../assets/jlpt.png";
import jlptdr from "../assets/jlptdr.png";
import hsk from "../assets/hskdr.png";
import hskdr from "../assets/hsk.png";
import availableb from "../assets/bolomjtoib.svg";
import unavailableb from "../assets/bolomjguib.svg";
import sbdbg from "../assets/sbd.png";
import sat from "../assets/sat.png";
import ielts from "../assets/ielts.png";

import topikdr from "../assets/topik-dr.png";
import ush from "../assets/ush.svg";
import esh from "../assets/esh.svg";
import oge from "../assets/oge.png";
import ege from "../assets/ege.png";

export const Shalgalta = () => {
  const mergejil = [];
  const [data, setData] = useState([]);
  const [col, setCol] = useState("");
  const location = useLocation();
  const bairshil = location.pathname;

  const groupByMonthYear = data.examday?.reduce((acc, el) => {
    const date = new Date(el.day);
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`; // Group by year and month (1-12)
    if (!acc[yearMonth]) acc[yearMonth] = [];
    acc[yearMonth].push(el);
    return acc;
  }, {});
  const dataRetriever = async (img) => {
    const fixedImgPath = img.split("../")[1];
    const imgUrl = `https://www.tmull.mn/${fixedImgPath}`;

    try {
      const response = await axios.get(
        `https://tmull-be.vercel.app/color?img=${encodeURIComponent(imgUrl)}`
      );
      console.log(response?.data);
      setCol(response?.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    document.title = "Tmull ・ Шалгалтууд";

    const examData = exams.find(
      (el) => el.n.toLowerCase() === bairshil.split("/")[2].toLowerCase()
    );

    if (examData) {
      setData(examData);
      if (examData.img) {
        dataRetriever(examData.img);
      }
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  console.log(data);

  const exams = [
    {
      img: "../assets/sat.png",
      n: "SAT",
      examday: [
        {
          day: "2025-03-08",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-05-03",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-08-23",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-10-04",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-12-06",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
      ],
      d: "SAT (Scholastic Assessment Test) бол АНУ-д коллеж болон их сургуульд элсэхэд өргөн хэрэглэгддэг стандартчилсан шалгалт юм. Зарим их дээд сургуулиуд бакалаврын элсэлт авахдаа шаарддаг.",
      c: [
        "SAT-ийн жишиг тестүүдийг байнга хийж дадлага хийх. Аль болох эртнээс бэлдэж эхлээрэй.",
        "Унших, бичих, математик дээр ижил анхаарал хандуулах.",
        "Өдөр бүр 1-ээс дээш шинэ үг цээжлээрэй.",
        "Шалгалтын цагийн менежментэд суралцах.",
        "Жил болгон Монголд 3,5,6,10,11,12 сард авдаг бөгөөд сарын сүүлээр маш хурдан суудал дүүрдэг учраас аль болох хавар өгчихвөл зүгээр.",
        "Та өөрийн мэдээлэл зөв эсэхийг давхар шалгах хэрэгтэй, эс бөгөөс таны нэрний нэг үсэг буруу байвал элсүүлэхгүй. Та мөнгөө буцааж авах боломжтой байж магадгүй юм.",
      ],
      w: [
        {
          n: "College Board",
          w: "https://www.collegeboard.org/",
          d: "College Board вэбсайт нь SAT болон AP тестийн бэлтгэл, их сургуулийн элсэлт, тэтгэлэг болон боловсролын нөөцийг санал болгодог. Bluebook аппликейшн (https://bluebook.app.collegeboard.org/) ашиглан оюутнууд SAT болон AP тестэд бэлтгэж, элсэлтийн тасалбар авч, тест өгч болно.",
        },
        {
          n: "Bluebook",
          w: "https://bluebook.app.collegeboard.org/",
          d: "Bluebook application-ыг ашиглан SAT болон AP-д бэлдэх, элсэлтийн тасалбар авах, тест өгөх боломжтой юм.",
        },
        {
          n: "Khan Academy",
          w: "https://www.khanacademy.org/test-prep/digital-sat",
          d: "Энэхүү веб хуудаснаас математик, дүрэм, шинжлэх ухаан, түүх, SAT, AP-ын гэх мэт мэдлэгийг үнэгүй авах боломжийг олгодог.",
        },
      ],
      t: [
        {
          Өдөр: "Жилд 7 удаа (3, 5, 6, 8, 10, 11, 12 сар)",
          Бүртгэл: "Шалгалтаас 1-2 сарын өмнө",
          Хариу: "Шалгалтаас 2 долоо хоногийн дараа",
        },
      ],
      v: [
        "https://www.youtube.com/user/SuperTutorTV",
        "https://youtu.be/pqW3j9IgvN8",
        "https://youtube.com/playlist?list=PLXmJvdMwHZJ-UdQx7Y79LluJpYblQK9yV&si=K5kJ3rBY1j_C9oaX",
      ],
      m: [
        "https://thecollegepanda.com/categories/sat-math/",
        "https://www.cracksat.net/sat/reading/",
        "https://satsuite.collegeboard.org/sat/practice-preparation/practice-tests/linear#",
      ],
      s: "SAT нь 2 хэсэгтэй: Unscored Essay болон Optional Essay. Унших, бичих, математикийн хэсэг тус бүр 800 онооны үнэлгээтэй. Шалгалтын нийт хугацаа 3 цаг 45 минут бөгөөд маягт бөглөх, материал тараах, 10 минут ба түүнээс дээш завсарлага зэргээс шалтгаалан ерөнхийдөө 4 цагаас илүү байдаг.",
      p: "1600 оноо нийт оноо (800 унших ба бичих, 800 математик).",
      g: "College Board-оос бүртгүүлнэ, шалгалтын төв, өдрийг сонгож, төлбөрөө төлнө. Бүртгэлийн хураамж 68$, олон улсын төлбөр нь 43$, гадаад оюутнуудад нийт 111$ төлнө.",
      r: "https://www.collegeboard.org/",
      f: "Бүртгэлийн хураамж 68$, олон улсын төлбөр нь 43$, гадаад оюутнуудад нийт 111$ төлнө.",
      a: [
        "Сайн унтаж амрах.",
        "Шаардлагатай бичгийн хэрэгслээ (тооны машин, харандаа гэх мэт) авчрах. Харандаа (HB харандаа, үзүүрлэсэн байх ёстой), баллуур, харандаа үзүүрлэгч, тооны машиныг жижиг тунгалаг гялгар уутанд хийж, шалгахад хялбар болгоно. Эдгээр зүйлсийг объекттой холбоо тогтоохыг багасгахын тулд энэ жил зээлж авах боломжгүй.",
        "Шалгалтын төвдөө цагтаа очих.",
        "Бүх зууш/ундаа/усны савыг ширээн доороо хийх жижиг уутанд хий.",
        "Таны гар утас болон бусад төхөөрөмж(ухаалаг цаг)-ийг унтрааж, цүнхэндээ хийх ёстой. Шалгалтын өрөөнд орсны дараа та шалгалтаа дуусах хүртлээ цүнхээ ашиглах боломжгүй болно. Цүнхийг найдвартай газар байрлуулна. Та өрөөндөө орсны дараа л энгийн бичгийн хэрэгсэл, тооцоолуур, хөнгөн зууштай болно.",
        "Та элсэлтийн тасалбараа хэвлэсэн байх шаардлагатай (энэ нь зөв эсэхийг шалгаарай), зурагтай үнэмлэхээ авах шаардлагатай.",
      ],
      day: "Шалгалт өгснөөс хойш 13 хоногийн дараа online-аар хариугаа харж болно. Харин түүнээс 3 өдрийн дараа эсээний хариу, мөн түүнээс 12 өдрийн дараа таны үнэгүй сонголтоор сонгосон 4 сургуульд оноо тань очсон байх болно.",
      i: "Ахлах сургуулиа төгсөх эсвэл төгссөн сурагчид",
      wn: [
        "Гадаад улсад суралцах болон тэтгэлэг авхыг хүсэж байгаа хэн болгон энэ шалгалтыг өгөх хэрэгтэй.",
      ],
      l: [
        "Orkhon KhaSu School",
        "American School of Ulaanbaatar",
        "English Academy of Must",
        "INTL SCH of Ulaanbaatar",
        "Jet School of English",
        "Nest Education High School",
        "Orchlon International School",
        "Selbe School",
        "Ulaanbaatar Empathy School",
      ],
    },
    {
      img: "../assets/ielts.png",
      n: "IELTS",
      examday: [
        { day: "2025-01-24", loc: ["English Academy of MUST"], t: "computer" },
        {
          day: "2025-01-31",
          loc: ["ESP Foundation", "JET Track"],
          t: ["computer", "paper"],
        },
        { day: "2025-02-01", loc: ["ESP Foundation"], t: "paper" },
        { day: "2025-02-01", loc: ["JET Track"], t: "computer" },
        {
          day: "2025-02-07",
          loc: ["English Academy of MUST", "JET Track", "ESP Foundation"],
          t: "computer",
        },
        { day: "2025-02-08", loc: ["ESP Foundation"], t: "paper" },
        { day: "2025-02-08", loc: ["JET Track"], t: "computer" },
        { day: "2025-02-12", loc: ["ESP Foundation"], t: "computer" },
        { day: "2025-02-13", loc: ["ESP Foundation"], t: "paper" },
        {
          day: "2025-02-14",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        {
          day: "2025-02-15",
          loc: ["JET Track", "ESP Foundation", "English Academy of MUST"],
          t: "computer",
        },
        { day: "2025-02-19", loc: ["ESP Foundation"], t: "computer" },
        {
          day: "2025-02-21",
          loc: ["English Academy of MUST", "JET Track", "ESP Foundation"],
          t: "computer",
        },
        {
          day: "2025-02-22",
          loc: ["English Academy of MUST", "ESP Foundation", "JET Track"],
          t: ["computer", "paper"],
        },
        { day: "2025-03-06", loc: ["ESP Foundation"], t: "paper" },
        {
          day: "2025-03-07",
          loc: ["ESP Foundation", "JET Track"],
          t: "computer",
        },
        { day: "2025-03-08", loc: ["ESP Foundation"], t: "paper" },
        { day: "2025-03-12", loc: ["ESP Foundation"], t: "computer" },
        {
          day: "2025-03-14",
          loc: ["English Academy of MUST", "JET Track", "ESP Foundation"],
          t: "computer",
        },
        {
          day: "2025-03-15",
          loc: ["English Academy of MUST", "ESP Foundation", "JET Track"],
          t: ["computer", "paper"],
        },
        { day: "2025-03-19", loc: ["ESP Foundation"], t: "computer" },
        {
          day: "2025-03-21",
          loc: ["English Academy of MUST", "JET Track", "ESP Foundation"],
          t: "computer",
        },
        {
          day: "2025-03-22",
          loc: ["ESP Foundation", "JET Track"],
          t: ["paper", "computer"],
        },
        { day: "2025-03-26", loc: ["ESP Foundation"], t: "computer" },
        {
          day: "2025-03-28",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        {
          day: "2025-03-29",
          loc: ["JET Track", "ESP Foundation"],
          t: "computer",
        },
        { day: "2025-04-02", loc: ["ESP Foundation"], t: "computer" },
        {
          day: "2025-04-04",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        {
          day: "2025-04-05",
          loc: ["ESP Foundation", "JET Track", "English Academy of MUST"],
          t: ["paper", "computer"],
        },
        { day: "2025-04-09", loc: ["ESP Foundation"], t: "computer" },
        {
          day: "2025-04-11",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        {
          day: "2025-04-12",
          loc: ["English Academy of MUST", "ESP Foundation", "JET Track"],
          t: ["computer", "paper"],
        },
        {
          day: "2025-04-18",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        {
          day: "2025-04-19",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        { day: "2025-04-24", loc: ["ESP Foundation"], t: "paper" },
        {
          day: "2025-04-25",
          loc: ["English Academy of MUST", "JET Track"],
          t: "computer",
        },
        { day: "2025-04-26", loc: ["ESP Foundation"], t: "paper" },
        { day: "2025-04-30", loc: ["ESP Foundation"], t: "computer" },
        { day: "2025-05-02", loc: ["JET Track"], t: "computer" },
        {
          day: "2025-05-03",
          loc: ["ESP Foundation", "JET Track"],
          t: ["paper", "computer"],
        },
        { day: "2025-05-07", loc: ["ESP Foundation"], t: "computer" },
        { day: "2025-05-08", loc: ["ESP Foundation"], t: "paper" },
        { day: "2025-05-09", loc: ["JET Track"], t: "computer" },
        { day: "2025-05-10", loc: ["JET Track"], t: "computer" },
        { day: "2025-05-16", loc: ["JET Track"], t: "computer" },
        { day: "2025-05-17", loc: ["JET Track"], t: "computer" },
        { day: "2025-05-17", loc: ["ESP Foundation"], t: "paper" },
        {
          day: "2025-05-23",
          loc: ["JET Track", "English Academy of MUST"],
          t: "computer",
        },
        {
          day: "2025-05-24",
          loc: ["ESP Foundation", "English Academy of MUST"],
          t: ["paper", "computer"],
        },
        { day: "2025-06-06", loc: ["JET Track"], t: "computer" },
        { day: "2025-06-07", loc: ["JET Track"], t: "computer" },
        { day: "2025-06-13", loc: ["JET Track"], t: "computer" },
        { day: "2025-06-14", loc: ["JET Track"], t: "computer" },
        {
          day: "2025-06-20",
          loc: ["JET Track", "English Academy of MUST"],
          t: "computer",
        },
        { day: "2025-06-21", loc: ["JET Track"], t: "computer" },
        { day: "2025-06-27", loc: ["JET Track"], t: "computer" },
        { day: "2025-06-28", loc: ["JET Track"], t: "computer" },
        { day: "2025-07-04", loc: ["English Academy of MUST"], t: "computer" },
      ],
      d: "IELTS (International English Language Testing System) нь Англи хэлний чадварыг үнэлдэг олон улсын түвшний шалгалт юм. Academic болон General Training гэсэн 2 төрөлтэй. Academic нь их, дээд сургуульд элсэн суралцахад чиглэгдсэн бол General Training нь ажлын байр, цагаачлалд зориулсан шалгалт юм.",
      c: [
        "Өдөр тутамд Англи хэл дээр уншиж, бичих дасгал хийж хэвшээрэй.",
        "Сонсох чадвараа сайжруулахын тулд подкаст, YouTube видео, эсвэл аудио ном сонсоорой.",
        "Шалгалтын бүтэц, асуултын төрлийг сайн судалж, жишиг тестүүдээр бэлтгэл хийх.",
      ],
      w: [
        {
          n: "IELTS Official Website",
          w: "https://ielts.org/",
          d: "IELTS-ийн албан ёсны вэбсайт нь тестийн мэдээлэл, бүртгэл, бэлтгэл сургалтыг санал болгодог.",
        },
        {
          n: "IELTS Liz",
          w: "https://ieltsliz.com/",
          d: "IELTS Liz вэбсайт нь IELTS тестийн бэлтгэл материал, зөвлөмжүүдийг өгдөг.",
        },
        {
          n: "British Council IELTS Preparation",
          w: "https://takeielts.britishcouncil.org/take-ielts/prepare",
          d: "British Council-ийн вэбсайт нь IELTS тестийн бэлтгэл хийхэд туслах үнэгүй сургалтуудыг санал болгодог.",
        },
      ],
      t: [
        {
          Өдөр: "Жилд олон удаа (сар бүр зохион байгуулна)",
          Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
          Цаг: "Өглөө 9:00 цагт эхэлдэг",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=6-VlNg3n7cg",
        "https://www.youtube.com/watch?v=gfKS-p7thDU",
        "https://www.youtube.com/watch?v=r5eiUU3EpHE",
      ],
      m: [
        "'The Official Cambridge Guide to IELTS'.",
        "'IELTS Trainer' (Cambridge).",
        "'Barron's IELTS Superpack'.",
        "'IELTS Liz' цахим сурах материал.",
      ],
      s: "Listening (30 минут), Reading (60 минут), Writing (60 минут), Speaking (11-14 минут).",
      p: "IELTS нь 0-9 хүртэл оноотой. Сонсгол, уншлага, бичих, ярих чадвар тус бүрт 0-9 хооронд оноо өгөгдөх бөгөөд эдгээр оноонуудын дундаж нь таны ерөнхий оноог тодорхойлно.",
      g: "IELTS.org руу орж, хаяг үүсгэнэ, шалгалтын төв, өдрөө сонгож, төлбөрөө төлнө.",
      r: "https://ielts.org/",
      f: "785,000 төгрөг (дундаж)",
      a: [
        "Сайн унтаж амарсан байх.",
        "Шалгалтын төвдөө цагтаа ирэх.",
        "Бичиг баримтаа бэлэн байлгах.",
      ],
      day: "Цаасан тест: 13 хоног, Компьютер тест: 3-5 хоног.",
      i: "Бүх насныхан.",
      wn: [
        "Их сургуульд элсэгчид.",
        "Ажилд орох өргөдөл гаргагчид.",
        "Цагаачлалын өргөдөл гаргагчид.",
      ],
      l: [
        "Орон нутгийн British Council эсвэл IDP IELTS төвүүдэд шалгалт авдаг.",
      ],
    },
    {
      img: "../assets/topikk.png",
      examday: [
        {
          day: "2025-04-13",
          loc: ["Korean Cultural Center Ulaanbaatar"],
          t: "paper",
        },
        {
          day: "2025-04-13",
          loc: ["International School of Ulaanbaatar"],
          t: "paper",
        },
        {
          day: "2025-10-19",
          loc: ["Korean Cultural Center Ulaanbaatar"],
          t: "paper",
        },
        {
          day: "2025-10-19",
          loc: ["International School of Ulaanbaatar"],
          t: "paper",
        },
      ],
      imgdr: "../assets/topik-dr.png",
      n: "TOPIK",
      d: "TOPIK (Test of Proficiency in Korean) бол Солонгос хэлний мэдлэгийг шалгах олон улсын шалгалт юм. TOPIK I: Анхан шат (1-2 түвшин). TOPIK II: Дунд ба ахисан шат (3-6 түвшин).",
      c: [
        "Солонгос хэлний дүрэм, үгийн санг өдөр бүр давтаж, дадлага хийх.",
        "Сонсох, унших, бичих чадвараа тэнцвэртэй хөгжүүлэх.",
        "Өмнөх шалгалтын жишиг даалгавруудыг ашиглан дадлага хийх.",
        "Солонгос хэл дээр кино үзэж, аудио материал сонсож, харилцан ярианы чадвараа хөгжүүлэх.",
      ],
      w: [
        {
          n: "TOPIK Guide",
          w: "https://www.topikguide.com/",
          d: "TOPIK Guide вэбсайт нь TOPIK (Test of Proficiency in Korean) тестийн бэлтгэл, мэдээлэл, сургалтын нөөцийг санал болгодог.",
        },
        {
          n: "Talk To Me In Korean",
          w: "https://talktomeinkorean.com/",
          d: "Talk To Me In Korean вэбсайт нь Солонгос хэлний сургалтууд болон үнэгүй материалуудыг санал болгодог.",
        },
        {
          n: "TOPIK Exam",
          w: "https://topikexam.com/en",
          d: "TOPIK Exam вэбсайт нь TOPIK тестийн бэлтгэл хийх, шалгалт авах боломжтой.",
        },
      ],
      t: [
        {
          Өдөр: "2025 оны 04.13 (99 дэх), 05.11(100 дахь), 10.19(102 дахь), 11.16(103 дахь) (Ням)",
          Бүртгэл: "Бүртгэл 3 сарын өмнө нээгдэж, 4-5 хоног үргэлжилнэ.",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=hLf2IVQ5PGU",
        "https://www.youtube.com/watch?v=3msmx3w5J7Y",
        "https://www.youtube.com/watch?v=RxnPTtP2SWo",
      ],
      m: [
        "'한국어능력시험 초급' (TOPIK I-д зориулсан ном).",
        "'한국어능력시험 중급 및 고급' (TOPIK II-д зориулсан ном).",
        "Өмнөх жилийн TOPIK-ийн жишиг тестүүд.",
        "Солонгосын соёлын төвөөс гаргасан сургалтын материал.",
      ],
      s: "TOPIK I: Сонсох (30 асуулт, 40 минут), Унших (40 асуулт, 60 минут). TOPIK II: Сонсох (50 асуулт, 60 минут), Унших (50 асуулт, 70 минут), Бичих (2 даалгавар, 50 минут).",
      p: "TOPIK I: 200 онооны дотор. TOPIK II: 300 онооны дотор.",
      g: "Албан ёсны вебсайтад бүртгүүлэх, шаардлагатай мэдээллийг бөглөж, төлбөрөө төлөх.",
      r: "https://topik.mn/",
      f: " TOPIK I: ойролцоогоор 70,000–80,000 төгрөг; TOPIK II: ойролцоогоор 100,000–120,000 төгрөг",
      a: [
        "Эрч хүчээ ухаалгаар хуваарилах.",
        "Иргэний үнэмлэх эсвэл паспортоо шалгах.",
        "Шаардлагатай бичгийн хэрэгслээ авчрах.",
      ],
      day: "Шалгалтын дараа 4-6 долоо хоногийн дотор албан ёсны вебсайтад байршина.",
      i: "Солонгос хэл суралцаж буй хэн бүхэн.",
      wn: [
        "Солонгост суралцах хүсэлтэй оюутнууд.",
        "Ажилд ороход хэлний батламж шаардагддаг хүмүүс.",
        "Хэлний түвшнээ тодорхойлохыг хүссэн хувь хүмүүс.",
      ],
      l: ["МУИС-ийн хичээлийн 2,4,5-р байр"],
    },
    {
      img: "../assets/hskdr.png",
      examday: [
        { day: "2025-03-09", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-01-12", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-04-12", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-05-17", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-06-22", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-08-17", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-09-07", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-10-18", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-11-15", loc: ["Confucius Institute"], t: "paper" },
        { day: "2025-12-07", loc: ["Confucius Institute"], t: "paper" },
      ],
      imgdr: "../assets/hsk.png",
      n: "HSK",
      d: "Хятад хэлний түвшин тогтоох шалгалт (HSK) нь CTI компаниас зохион байгуулдаг, олон улсын хятад хэлний түвшин тогтоох олон улсын стандартчилсан шалгалт юм. HSK нь Хятад хэл суралцагчдын өдөр тутмын, эрдэм шинжилгээний болон мэргэжлийн амьдралдаа Хятад хэлийг хоёр дахь хэл болгон ашиглах чадварыг стандарт нь шалгалтын нэгдсэн үнэлгээ, онооны системээр үнэлдэг.",
      c: [
        "Тогтмол шинэ үг цээжлэж, үгийн сангаа сайжруулаарай.",
        "Унших, бичих, сонсох дасгалаа бэлдэж, сайжруулаарай.",
        "Тууштай байж шалгалтын өдөр аль болох тайван байхыг хичээгээрэй!",
      ],
      w: [
        {
          n: "Duolingo",
          w: "https://www.duolingo.com",
          d: "Хятад хэлний үгийн сан, дүрэм, үндсэн хэллэгт зориулсан дасгалуудыг санал болгодог.",
        },
        {
          n: "HSK Online",
          w: "https://www.hskonline.com",
          d: "HSK-ийн бүх түвшинд үнэ төлбөргүй mock test өгөх, үгийн сангийн дадлага хийх боломжтой.",
        },
        {
          n: "HelloChinese",
          w: "https://www.hellochinese.cc/",
          d: "Хятад хүний яриа сонсох, сонирхолтой түүхүүд унших, яриагаа бичиж дуудлага сайжруулах, ханзыг зөв бичиж сурах зэрэг олон сонирхолтой байдлаар сурах боломжтой.",
        },
      ],
      l: [
        "Үй Цай сургуулийн CRI Күнзийн анги - HSK, BCT, YCT, HSKK",
        "Ховд их сургуулийн Күнзийн институт(IBT) - HSK, HSKK",
        "HSK Итгэл - HSK, BCT, YCT, HSKK",
        "Монгол Улсын Их Сургууль - HSK, HSKK",
      ],
      t: [
        {
          Өдөр: "Сар бүр зохион байгуулна",
          Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
          Цаг: "HSK 2,4,6 өглөө 9:00, ярианы шалгалт 14:00, HSK 1,3,5 үдээс эхэлнэ.",
          Хариу: "Цаасан тест: 2-3 хоног, Компьютер тест: 7-10 хоног.",
        },
      ],
      v: [
        "https://youtu.be/FlaJ12tmtu4?si=FBvCrcjM9AjFHEIr",
        "https://youtu.be/WyehfFj72zY?si=OiWx93AhgLJnUv72",
        "https://youtube.com/playlist?list=PL2pHdzrcvbMjvwtYVWV8HEjpNzJyPCdoj&si=C_zLtTDZEi72GeuN",
      ],
      m: [
        "Mandarin Bean- https://mandarinbean.com/hsk-chinese-test-online/ - HSK1-6 түвшний сонсох болон унших дасгал хийж болно.",
      ],
      s: "Суурь шат: Сонсгол, хэл зүй, сонгох дасгал; Дунд шат: Сонсгол, хэл зүй, сонгох дасгал, хүснэгт бөглөх; Дээд шат: Сонсгол, хэл зүй, сонгох дасгал, хүснэгт бөглөх, зохиол бичих, аман яриа.",
      p: "HSK 1, 2-р зэргийн шалгалтын нийт оноо 200 байдаг бол 120 оноогоор тэнцэнэ. HSK 3-6-р зэргийн шалгалтын нийт оноо 300 байдаг бол 180 оноогоор тэнцдэг.",
      g: "ChineseTest website руу орно. Өөрийн хаягийг нээнэ. Шалгалт өгөх түвшин, цаас эсвэл интернетээр, болон өдрөө сонгоно. Хувийн мэдээллийг оруулна. (Иргэний үнэмлэхний дугаар, хүйс, төрсөн он/сар/өдөр гэх мэт). Цээж зургаа оруулна. Шалгалтны төлбөрийг төлнө. Шалгалтын зөвшөөрлийн бичиг авна. Та өөрийн багш болон сургалт, шалгалтын төвөөс тусламж авч, бүртгүүлж болно.",
      r: "https://www.chinesetest.cn/",
      f: "Түвшингээсээ хамааран 150¥-оос 650¥ байна.",
      a: [
        "Шалгалтын өдөр сайн амарч, тайван байх.",
        "Бичиг баримтаа бүрэн бэлдэж ирэх. (HSK зөвшөөрөл, Хүчинтэй иргэний үнэмлэх)",
        "Харандаа, баллуур",
      ],
      day: "Шалгалтын гэрчилгээг шалгалт өгснөөс хойш 1 сарын дараа олгодог ба 2 жилийн хугацаанд хүчинтэй.",
      i: "Нас, харьяалал, гарал үүсэл харгалзахгүйгээр хэн ч өгч болно.",
      wn: [
        "Хятад хэл суралцагчид.",
        "Мэргэжлийн болон эрдэм шинжилгээний зориулалттай хүмүүс.",
        "Цагаачлал болон ажил хайж буй хүмүүс.",
        "HSK Хятад улсын их дээд сургуулиудад суралцах болон Хятад улсад ажиллахаар ирэх гадаадын иргэдээс Хятад улс HSK шалгалтыг өгсөн байхыг шаарддаг.",
      ],
      l: [
        "Шалгалтын газар бүр өөр өөр байдаг тул бүртгүүлэх дээ харах боломжтой.",
      ],
    },
    {
      img: "../assets/jlpt.png",
      examday: [
        {
          day: "2025-07-07",
          loc: ["МУИС 2-р байр", "МУИС 4-р байр", "МУИС 5-р байр"],
        },
        {
          day: "2025-12-01",
          loc: ["МУИС 2-р байр", "МУИС 4-р байр", "МУИС 5-р байр"],
        },
      ],
      imgdr: "../assets/jlptdr.png",
      n: "JLPT",
      d: "JLPT (Japanese-Language Proficiency Test) нь Япон хэл сурагчдад зориулсан, Япон хэлний мэдлэгийг шалгах олон улсын шалгалт юм. Энэ шалгалт нь 5 түвшинд хуваагддаг: N1 (хамгийн өндөр) - N5 (хамгийн доод). Япон хэлийг унших, сонсох, ойлгох чадварыг шалгадаг.",
      l: ["ЯПОН САН, Монголын Япон Хэлний Багш нарын Холбоо"],
      c: [
        "Өдөр бүр тодорхой хугацаанд сурах",
        "Сонсгол дээр анхаарах",
        "Жишиг тестүүд сайн ажиллах",
        "7-р сарын шалгалт ЕЭШ(ЭШ)-тэй давхцах боломжтой",
      ],
      w: [
        {
          n: "Easy Japanese",
          w: "https://easyjapanese.net/jlpt-test/",
          d: "Жишиг шалгалтууд",
        },
        {
          n: "JLPT Sensei",
          w: "https://jlptsensei.com/",
          d: "Бүх дүрэм ба ханзнуудыг цогцлоосон",
        },
        {
          n: "Tuttle Publishing",
          w: "https://www.tuttlepublishing.com/jlpt-study-guide",
          d: "Жишиг дасгал даалгаврууд",
        },
      ],
      t: [
        {
          Өдөр: "JLPT Тест нь жил бүр 2 удаа зохион байгуулагддаг. 7, 12-р сард зохион байгуулдаг",
          Бүртгэл: "Шалгалтын өдрөөс 2 сарын өмнө",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=47xmiuDBEG8",
        "https://www.youtube.com/watch?v=D21ZMN_B0hk",
        "https://www.youtube.com/watch?v=xYrOvyDa740",
      ],
      m: ["JLPT Япон хэлний ном", "The Official Practice Workbook Vol. 2"],
      s: "Шалгалтын бүтэц: Хэлний мэдлэг (Үгийн сан)- 20-30 минут, Дүрэм, Унших 40-70 минут, Сонсох 30-55 минут. Нийт: N1-165мин, N2-155мин, N3-140мин, N4-115мин, N5-90 мин.",
      p: "Хэлний мэдлэг (Үгийн сан, Дүрэм)-60 оноо, Унших-60 оноо, Сонсох-60 оноо, Нийт: 180 оноо. Тэнцэх: N1-100 оноо, N2-90 оноо, N3-95 оноо, N4-90 оноо, N5-80 оноо.",
      g: "Бүртгэл: 2024-08-30 нээгдэж 2024-09-07 дуустал явагдана. jlpt.mn website дээр бүртгүүлнэ.",
      r: "https://jlpt.mn/",
      f: "Шалгалтын төлбөр: N1 ～ 70000 ₮, N2 ～ 70000 ₮, N3 ～ 65000 ₮, N4 ～ 55000 ₮, N5 ～ 55000 ₮.",
      day: "7 сарын шалгалт – 10 сарын эхээр, 12 сарын шалгалт – 3 сарын эхээр",
      a: [
        "Шалгалтын өдрийн өмнө сайн амраарай",
        "Хувийн материалаа бэлдэж аваарай",
        "Хамгийн чухал зүйл нь тайван байх",
      ],
      wn: "Японд их сургуульд суралцах болон ажиллах хүмүүс",
    },
    {
      img: "../assets/goethee.png",
      n: "Goethe-Zertifikat",
      examday: [
        {
          day: "2025-03-08",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-03-15",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-04-13",
          loc: [
            "Korean Cultural Center Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-06-14",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-09-13",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-10-04",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-10-19",
          loc: [
            "Korean Cultural Center Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-12-06",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-12-13",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
      ],
      d: "Goethe-Zertifikat нь Герман хэлний түвшинг баталгаажуулдаг олон улсын түвшинд хүлээн зөвшөөрөгдсөн шалгалт юм. Энэ нь Европын хэлний түвшний (CEFR) A1-ээс C2 хүртэлх түвшнийг хамардаг.",
      c: [
        "Герман хэлний дүрэм, үгийн санг өдөр бүр давтах.",
        "Сонсгол, уншлагын дадлагыг аудио болон текст материал ашиглан хийх.",
        "Германаар ярих чадвараа сайжруулахын тулд тухайн хэлээр ярьдаг орчинд өөрийгөө идэвхтэй оруулах (соёлын арга хэмжээнд оролцох, хэлэлцүүлгийн бүлгүүдэд элсэх)",
      ],
      w: [
        {
          n: "Goethe Institute",
          w: "https://www.goethe.de/en/spr/prf/vor.html",
          d: "Шалгалт өгөх бэлдэлт",
        },
        {
          n: "DW Learn German",
          w: "https://learngerman.dw.com/en/learn-german/s-9528",
          d: "Герман хэл сурах",
        },
        {
          n: "Lingoda",
          w: "https://www.lingoda.com/en/",
          d: "Онлайн герман хэлний сургалт",
        },
      ],
      l: ["Гёте-Институт Монгол"],
      t: [
        {
          Өдөр: "Одоогоор шалгалт өгөх хугацаа заагаагүй байна",
          Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
          Цаг: "Шалгалт болох цагийг шалгалтын хуваарийг шалгах",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=-Qt3llCTdkM&list=PLZ6nUCSTx9pKa8PEs0TBPMWTMeDa4gcsH",
        "https://www.youtube.com/watch?v=mLUTv35RigE&list=PLsyb8W0fpnBvLrntHk2TR8hQxddaTL-2V",
        "https://www.youtube.com/watch?v=dq1nFflRE94&list=PLsyb8W0fpnBvLrntHk2TR8hQxddaTL-2V&index=15",
      ],
      m: [
        "Menschen сурах бичгийн цуврал.",
        "Goethe-Zertifikat-ын дадлагын ном, гарын авлага.",
        "Герман хэлний дүрмийн гарын авлагууд.",
      ],
      s: "Шалгалтын бүтэц: Унших (Lesen): Олон сонголттой тест, текст ойлгох даалгавар. Сонсох (Hören): Аудио бичлэг сонсоод асуултад хариулах даалгавар. Бичих (Schreiben): Өгөгдсөн сэдвээр эссе эсвэл албан бичиг бичих даалгавар. Ярих (Sprechen): Хувийн танилцуулга, сэдэвчилсэн яриа.",
      p: "Тус бүрийн чадвараас 100 оноо авах боломжтой. Нийт оноо 60%-аас дээш байвал тэнцэнэ.",
      g: "Goethe-Institut-ын албан ёсны вэбсайт руу орох. Шалгалтын хуваарийг шалгах. Онлайн бүртгэлийн маягтыг бөглөх.",
      r: "https://www.goethe.de/ins/mn/mn/spr/prf.html",
      f: "Шалгалтын төлбөр: Гёте-Сертификат A1: Fit in Deutsch 1 - 55,000 төгрөг, Гёте-Сертификат A1: Start Deutsch 1 - 180,000 төгрөг, Гёте-Сертификат A2: Fit in Deutsch - 70,000 төгрөг, Гёте-Сертификат A2 - 200,000 төгрөг, Гёте-Сертификат B1 (өсвөр үеийнхэнд зориулсан) - 100,000 төгрөг, 30,000 төгрөг (1 хэсэг), Гёте-Сертификат B1 - 240,000 төгрөг, 70,000 төгрөг (1 хэсэг), Гёте-Сертификат B2 - 280,000 төгрөг, 80,000 төгрөг (1 хэсэг), Гёте-Сертификат C1 - 320,000 төгрөг, 90,000 төгрөг (1 хэсэг), Гёте-Сертификат C2 - 320,000 төгрөг, 90,000 төгрөг (1 хэсэг), 360,000 төгрөг, 100,000 төгрөг (1 хэсэг).",
      a: [
        "Шалгалтаас өмнө хангалттай амрах.",
        "Шалгалт эхлэхээс 30 минутын өмнө ирэх.",
        "Сэтгэл санаагаа тайван байлгах",
      ],
      i: "Герман хэлний мэдлэгээ баталгаажуулахыг хүссэн хэн бүхэн өгч болно.",
      wn: "Герман улсад суралцах хүсэлтэй оюутнууд. Ажилд ороход хэлний батламж шаардагддаг хүмүүс. Хэлний түвшнээ баталгаажуулахыг хүссэн хувь хүмүүс.",
      day: "Шалгалтын дараа 2-4 долоо хоногийн дотор хариу гарна.",
    },
    {
      n: "yesh",
      d: "ЭЕШ (Элсэлтийн Ерөнхий Шалгалт) нь Монгол улсын их, дээд сургуулиудад элсэхэд нь стандартчилсан шалгалт юм.",
      c: [
        "Шалгалтандаа эртнээс бэлдэж эхлээрэй",
        "Шалгалтын өмнөх өдрүүдэд өөрийгөө болон тархиа сайн амраагаарай",
        "Өөрийн дотоод мэдрэмждээ итгээрэй",
        "Тэжээллэг хоол хүнс хэрэглээрэй",
        "Шалгалтаа хэрхэн яаж өгөх талаар төлөвлөгөө гаргаарай",
      ],
      w: [
        {
          n: "ЭЕШ шалгалтын сайт",
          w: "http://eyeshitems.eec.mn/",
          d: "ЭЕШ-д бэлтгэх мэдээлэл",
        },
        {
          n: "iMath",
          w: "https://www.imath.mn",
          d: "Математикийн онлайн сургалт",
        },
      ],
      l: [
        "БҮТ-ийн албан ёсны вэбсайт (eec.mn)-аас шалгалтын байр, цагийн хуваарийг шалгана.",
      ],
      t: [
        {
          Өдөр: "Шалгалт болох өдөр, цагийг шалгалтын хуваарийг шалгах",
          Бүртгэл:
            "Бүртгэлийн хугацаа тодорхой, суудлын хуваарийн мэдээлэл шалгалтын бүртгэлд байна",
          Цаг: "Шалгалтын цагийг шалгалтын хуваарийг шалгах",
        },
      ],
      v: [
        "https://youtu.be/KsXQg6KfOxM?si=i3nB-SxutUAi7Nsl - 14 өдрийн дотор БИЕ ДААН ЭЕШ-д бэлдээд 750+ оноо авсан СТРАТЕГИ",
        "https://youtu.be/ZsSLgjkNCMY?si=AR0GV38Lg1uLMqvK - ЭЕШ 800 оноо авах 45 арга",
        "https://youtu.be/Mao62cvvHPA?si=DlKi6aRNRfcAI_cK - Хэрхэн үр дүнтэй хичээлээ хийх вэ?",
      ],
      m: [
        "9,10,11,12-р ангийн сурах бичгүүд",
        "ЭЕШ-д бэлтгэгчдэд зориулсан тестийн ном",
        "Шинэ жишиг даалгаврын эмхэтгэл",
        "1001 тест",
      ],
      s: "Тухайн хичээлээс хамааран өөр, өөр байна.",
      p: "200-800 оноо",
      g: "eyesh.eec.mn сайт руу орж бүртгэл цэс рүү орно. Регистрийн дугаар оруулах. Хувийн мэдээллээ оруулах. Сүүлийн 3 сард авхуулсан цээж зураг оруулах. Бүртгэлийн дугаар, нууц үг авах. Асуулга бөглөх. МХБШ-д бүртгүүлэх. ЭШ-ийн хичээлээ сонгох. Тухайн хичээл заадаг багш сонгох. Төлбөрөө төлөх. Бүртгэлийн хуудсаа хэвлэн авах.",
      r: "https://eyesh.eec.mn/",
      f: "ЭЕШ-ын нэг хичээлийн бүртгэлийн хураамж 5800 төгрөг, батламж хуудасны хураамж 700 төгрөг байна.",
      a: [
        "ДҮРМЭЭ ОЙЛГО: Тестийн материал хэрхэн зөв бөглөх талаар сайн ойлгох нь таныг өндөр оноо танд туслах хамгийн эхний сана юм.",
        "ТӨВЛӨР: Анхаарлаа зөвхөн шалгалтдаа дээрээс, өөр зүйлд сатаарах хэрэггүй.",
        "ХӨНГӨНӨӨС ХҮНД РҮҮ: Шалгалтыг заавал дарааллаар нь нэг бүрчлэн хийх хэрэггүй. Эхлээд хийж чадахаасаа эхлээд дараа нь хэцүү асуултдаа хариулаарай. үүнийг ингэхдээ яарч сандраад тестээ буруу бөглөхөөс сэргийлээрэй.",
        "ДУУСТАЛ УНШ: Цагаа хэмнэж байна гээд даалгавраа алгасан яарч сандарч унших хэрэггүй. Анхааралтай байж зөв уншаарай.",
        "НЭГ ЗҮЙЛД АНХААР: Шинэ даалгавар орсон л бол өмнө нь хийсэн бүх даалгавруудаа март. Зөвхөн одоо байгаа дээрээ л анхаар.",
        "ШУУД ХАС: Олон хариулт дунд сонголт хийх шаардлагагүй гэж үзэж байна.",
        "ШАЛГАХ ХУГАЦАА ҮЛДЭЭ: Хамгийн сүүлд бүх даалгавраа бөглөсөн байна уу үүнийг хориглох ёстой үлдээгээрэй.",
        "ШАНАЛАХ ХЭРЭГГҮЙ: Бүх даалгаврыг хийж амжихгүй нь гэж шаналах хэрэггүй. Чанаргүй олноос чанартай цөөн нь дээр шүү дээ.",
        "ЗӨВ ХУВААРИЛАЛТ: Цагаа зөв жолоодох хэрэгтэй. Нэг хүнд даалгавар дээр хамаг цагаа үрэх хэрэггүй шүү.",
      ],
      day: "Шалгалт өгсний дараа хариу гарах хугацаа зарлагдана.",
      i: "Бүрэн дунд боловсрол эзэмшсэн болон МХБШ-д тэнцсэн сурагчид",
      wn: "Их сургуульд сурах хүсэлтэй сурагчид",
    },
    {
      img: "../assets/duo.png",
      imgdr: "../assets/duo-dr.png",
      n: "DUOLINGO",
      d: "Duolingo English Test нь АНУ болон дэлхийн олон их, дээд сургуулиуд болон байгууллагуудын шаардлагад нийцсэн англи хэлний түвшинг тодорхойлох цахим шалгалт юм. Энэ шалгалт нь вэб дээр өргөн хэрэглэгддэг, хурдан, хямд, хялбар шалгалт бөгөөд дэлхийн аль ч улс орноос, интернэт холболттой газраас өгч болно.",
      c: [
        "Бэлтгэл хийх: Duolingo цаанаасаа бэлтгэл материал болон жишиг тестүүдийг санал болгодог.",
        "Практик хийх: Duolingo хэлний сургалтын аппыг ашиглан үгийн сан, хэлзүй, сонсох, унших чадваруудыг сайжруулах.",
        "Цагийн менежмент барих",
      ],
      w: [
        {
          n: "Duolingo",
          w: "https://www.duolingo.com/",
          d: "Duolingo-ийн үндсэн вэбсайт",
        },
        {
          n: "Duolingo English Test",
          w: "https://englishtest.duolingo.com/applicants",
          d: "Duolingo English Test-ийн вэбсайт",
        },
      ],
      l: [
        "Шалгалт нь онлайнаар тогтмол бүртгэгдэх ба, шалгалтаа хүссэн үедээ, хүссэн газартаа онлайнаар өгч болно.",
      ],
      v: [
        "https://www.youtube.com/watch?v=VrrsnFa_K3I",
        "https://www.youtube.com/watch?v=X76TcmE1rvw",
        "https://www.youtube.com/watch?v=avhEAqbccus",
      ],
      m: ["Duolingo App", "TED Talks", "BBC Learning English"],
      s: "Сонсох (Listening): 15 минут. Унших (Reading): 15 минут. Бичих (Writing): 10 минут. Яриа (Speaking): 10 минут.",
      p: "10-160 онооны хооронд шалгалт авна. 120+ оноо нь ихэнх их сургуулиудад шаардлагатай.",
      g: "Duolingo English Test-ийн албан ёсны вэбсайтаар орж, бүртгэлээ хийнэ. Бүртгэлд шаардлагатай мэдээллээ бөглөх, төлбөрөө хийх. Шалгалт өгөхөд бэлэн болсны дараа шалгалтаа эхлүүлнэ.",
      r: "https://englishtest.duolingo.com/register",
      f: "$65.00 (220k)",
      a: ["Шалгалтын өмнө интернэт холболтоо шалгах, камерыг зөв байрлуулах."],
      day: "Өдөртөө 39$ эсвэл 2 өдрийн дараа",
      i: "Англи хэлний түвшнээ тодорхойлох шаардлагатай хүмүүс.",
      wn: "Их сургуулиуд болон боловсролын байгууллагуудад элсэн суралцах хүсэлтэй оюутнуудад. Ажилд орохыг хүссэн хүмүүс, ажлын виз авах шаардлагатай хүмүүс.",
    },
    {
      img: "../assets/toefl.png",
      examday: [
        {
          day: "2025-03-01",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-03-08",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-03-15",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-04-13",
          loc: [
            "Korean Cultural Center Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-05-03",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-06-14",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-08-02",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-09-13",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-10-04",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-10-19",
          loc: [
            "Korean Cultural Center Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-12-06",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-12-13",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
      ],
      n: "TOEFL",
      d: "Test of English as a Foreign Language (TOEFL) нь англи хэлээр ярьдаг их дээд сургуульд элсэн суралцах хүсэлтэй төрөлх бус хүмүүсийн англи хэлний чадварыг хэмжих стандартчилсан шалгалт юм. TOEFL iBT шалгалт нь шалгуулагчийн сонсох, унших, ярих, бичих чадварыг хамгийн чухал зүйл болох анги дотор хослуулан ашиглах чадварыг шалгадаг.",
      c: [
        "Шалгалтын бүтцийг ажиглаад, цагийг зөв зохицуулах",
        "Academic Word List (AWL) гэх мэт үгсийн жагсаалтыг судалж, шинэ үгсийг контекстээр нь сурах",
        "Barron’s 500 Words for TOEFL, Quizlet зэргийг ашиглан үгийн сангаа баяжуулах",
      ],
      w: [
        {
          n: "Educational Testing Service",
          w: "https://www.ets.org/toefl.html",
          d: "Албан ёсны вэб сайт нь дадлагын тест, түүвэр асуулт, зааварчилгааг агуулдаг.",
        },
        {
          n: "Magoosh TOEFL",
          w: "https://magoosh.com/toefl",
          d: "Видео хичээл, дадлага хийх асуулт, үгсийн сангийн жагсаалт, шалгалт өгөх зөвлөмжийг санал болгодог.",
        },
        {
          n: "Exam English",
          w: "https://www.examenglish.com/toefl",
          d: "TOEFL-ын сонсох, унших, бичих, ба ярих бэлтгэл хийх шалгалтыг үнэгүй хийж болно.",
        },
      ],
      l: [
        "E-Pen TOEFL Testing Center",
        "English Language Academy MUST",
        "EARC Center",
        "Absolute School of English Language",
        "Yeti School of English Language",
        "Bilingual School of Language",
      ],
      t: [
        {
          Өдөр: "Жилд олон удаа (сар бүр зохион байгуулна)",
          Бүртгэл: "Шалгалтын өдрөөс 1-2 сарын өмнө",
        },
      ],
      v: [
        "https://youtu.be/woo8EJn42wQ?si=aJWm1toe-8I1x7Cc",
        "https://youtu.be/0cOnR24vLKw?si=BY0pdNEOVpMcI3aq",
        "https://youtu.be/q15K9ByEchw?si=OvrKr1RqJVU2Akfg",
      ],
      m: [
        "Barron’s TOEFL IBT",
        "Cambridge Preparation for the TOEFL IBT",
        "Kaplan’s TOEFL IBT Prep",
      ],
      s: "Шалгалтын бүтэц: Хэсэг: Унших - 35 минут - 20 асуулт, Сонсох - 36 минут - 28 асуулт, Ярих - 16 минут - 4 даалгавар, Бичих - 29 минут - 2 даалгавар.",
      p: "Нийт 0-120 оноо. 4 хэсэг тус бүр 0-30 оноо.",
      g: "Албан ёсны вебсайт болох ETS-рүү орж хаягаа үүсгэнэ. Нэвтрээд “Шалгалтанд бүртгүүлэх” буюу “Register for a test” хэсгийг сонгоно. TOEFL IBT-г сонгож, өөрийн хаяг (жишээ нь: Улаанбаатар) болон шалгалтын өдрийг сонгоно.",
      r: "https://www.ets.org/toefl",
      f: "$235-$285 (880k)",
      a: [
        "Шалгалтын амралтын өдөр тархиа сайн амраасан байх нь чухал",
        "Аль болох өөртөө тухтай хувцас өмссөн байвал сайн",
        "Шалгалт өгөх газраа дор хаяж 30 минутын өмнө ирсэн байх",
        "Өөртөө итгэлтэй, тайван байж, хэсэг бүр дээр алхам алхамаар анхаарлаа төвлөрүүлээрэй",
        "Нэг асуултанд хэт их цаг зарцуулахаас зайлсхий. Хэрэв та итгэлгүй байвал дараагийн хэсэг рүү шилжээрэй.",
      ],
      day: "Ойролцоогоор 4-8 өдрийн дараа email хаяг руу очно.",
      i: "Гадаадад суралцах хүсэлтэй, Англи хэлний түвшнээ шалгах зорилготой хүмүүс.",
      wn: "TOEFL® нь төрөлх бус англи хэлтэй хүмүүсийн Хойд Америкийн англи хэлийг коллеж, их сургуулийн орчинд ярьж, бичиж, сонсох байдлаар ашиглаж, ойлгох чадварыг шалгадаг.",
    },
    {
      img: "../assets/act.png",
      n: "ACT",
      examday: [
        {
          day: "2025-02-08",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-03-01",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-03-08",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-03-15",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-04-12",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-04-13",
          loc: [
            "Korean Cultural Center Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-05-03",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-06-14",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper/digital",
        },
        {
          day: "2025-07-12",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-08-02",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-09-13",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-10-04",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-10-19",
          loc: [
            "Korean Cultural Center Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "paper",
        },
        {
          day: "2025-10-25",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-12-06",
          loc: [
            "American School of Ulaanbaatar",
            "International School of Ulaanbaatar",
          ],
          t: "digital",
        },
        {
          day: "2025-12-13",
          loc: [
            "National University of Mongolia",
            "German Language Center Ulaanbaatar",
          ],
          t: "paper",
        },
      ],
      d: "ACT (American College Testing) нь АНУ-ын их, дээд сургуулиудад элсэхэд шаардагддаг стандарчилсан шалгалт юм. Энэ нь сурагчдын академик чадварыг үнэлж, уншлага, математик, шинжлэх ухаан, англи хэлний ур чадвар дээр суурилдаг. Суралцагчид 1-36 онооны хооронд үнэлгээ авдаг ба дундаж оноо нь сургуулийн элсэлтийн шаардлагыг хангах гол шалгуур болдог.",
      c: [
        "Тогтмол тууштай бэлдэх нь чухал.",
        "Шалгалтын бүтэц, цагийн хуваарийг сайн судлах",
        "Жишиг тестүүд сайн ажиллах",
      ],
      w: [
        {
          n: "ACT Test Preparation",
          w: "https://www.act.org/content/act/en/products-and-services/the-act/test-preparation.html",
          d: "ACT-ийн албан ёсны бэлдэх материалыг агуулдаг.",
        },
        {
          n: "Khan Academy",
          w: "https://www.khanacademy.org/",
          d: "Үнэгүй бэлдэх сургалт.",
        },
        {
          n: "PrepScholar",
          w: "https://www.prepscholar.com/act-test-prep/",
          d: "Бэлдэх төлөвлөгөө, суралцах материал санал болгодог.",
        },
        {
          n: "CrackAB",
          w: "https://www.crackab.com/",
          d: "ACT тестийн бэлтгэлийн хэрэгсэл.",
        },
      ],
      t: [
        {
          Бүртгэл:
            "Шалгалтын өдрөөс 5-6 долоо хоногийн өмнө бүртгүүлэх шаардлагатай.",
          Өдөр: " Жилд 7 удаа (2, 4, 6, 7, 9, 10, 12 сард) зохион байгуулагддаг.",
          Цаг: "Шалгалт нийт 3 цаг 35 минут үргэлжилдэг (Writing хэсэгтэй бол).",
        },
      ],
      l: [
        "ACT шалгалт нь олон улсын болон АНУ-ын итгэмжлэгдсэн шалгалтын төвүүдэд зохион байгуулагддаг.",
      ],
      v: [
        "https://www.youtube.com/watch?v=f2DnOdLBG0k&t=80s",
        "https://www.youtube.com/watch?v=8euL7Z8FVL4",
        "https://www.youtube.com/watch?v=Z5f3tVAekNs",
      ],
      m: [
        "The Official ACT Prep Guide",
        "Princeton Review ACT Premium",
        "Kaplan ACT Prep Plus",
        "Barron's ACT",
        "Khan Academy, UWorld",
      ],
      s: "Шалгалтын бүтэц: English - 45 минут - 75 асуулт, Math - 60 минут - 60 асуулт, Reading - 35 минут - 40 асуулт, Science - 35 минут - 40 асуулт, Writing - эссэ бичлэг.",
      p: "Хэсэг бүрийг 1-36 хооронд үнэлдэг. 4 хэсгийн дундаж нь таны ерөнхий оноо (Composite Score) болно. Writing хэсэг нь 2-12 оноотой, нийт оноонд нөлөөлөхгүй.",
      g: "ACT.org руу орж, хэрэглэгчийн бүртгэл үүсгэнэ. Шалгалтын өдөр, байршлаа сонгоно. Хувийн мэдээллээ оруулж, шалгалтын төлбөрөө төлнө.",
      r: "https://www.act.org/",
      f: "Writing хэсэггүй: $186.5, Writing хэсэгтэй: $211",
      a: [
        "Сайн унтаж амарч, эрч хүчтэй ирээрэй.",
        "Шалгалтын бичиг баримт, үзэг, харандаа, тооны машин зэргээ авчрах.",
        "Тайван байж, бүх асуултад анхааралтай хариулахыг хичээгээрэй.",
      ],
      day: "Хариу шалгалтын дараа 2-8 долоо хоногийн дотор гарна. Writing хэсгийн хариу 10-12 долоо хоногт гардаг.",
      i: "АНУ болон олон улсын их сургуульд элсэхээр бэлтгэж буй 10-12 дугаар ангийн сурагчид (16-18 настнууд).",
      wn: "АНУ-ын их, дээд сургуульд элсэх хүсэлтэй сурагчид, сургуулийн тэтгэлэгт өргөдөл гаргагчид, SAT-ийн оронд ACT шалгалтыг өгөхийг илүүд үзсэн сурагчид.",
    },
    {
      n: "A-Level",
      examday: [
        {
          day: "2025-05-06",
          loc: "International School of Ulaanbaatar",
          t: "paper",
        },
        { day: "2025-05-06", loc: "British School of Ulaanbaatar", t: "paper" },
        {
          day: "2025-10-07",
          loc: "International School of Ulaanbaatar",
          t: "paper",
        },
        { day: "2025-10-07", loc: "British School of Ulaanbaatar", t: "paper" },
      ],
      d: "A-Level (Advanced Level) нь Их Британи болон зарим улсад дунд сургуулийн төгсөгчдөд зориулсан ахисан түвшний шалгалт бөгөөд оюутнуудын их, дээд сургуульд элсэх эрхийг баталгаажуулдаг. Энэ шалгалт нь үндсэн 2 жилийн хөтөлбөрөөс бүрдэнэ.",
      c: [
        "Өөрийн судалж буй хичээлүүдийн үндсэн агуулгыг сайн ойлгох.",
        "Өдөр бүр тогтмол цаг гаргаж, давтлага хийх.",
        "Өмнөх жилийн шалгалтын материал ажиллах.",
        "Хувийн цагийн хуваарь гаргаж, төлөвлөгөөтэй суралцвал үр дүнтэй.",
      ],
      w: [
        {
          n: "Physics and Maths Tutor",
          w: "https://www.physicsandmathstutor.com/",
          d: "Ахисан түвшний хичээлийн хөтөлбөрт зориулсан сургалт.",
        },
        {
          n: "BBC Bitesize",
          w: "https://www.bbc.co.uk/bitesize",
          d: "Англи хэл дээр ахисан түвшний сургалтын эх сурвалж.",
        },
        {
          n: "Save My Exams",
          w: "https://www.savemyexams.com/a-level/",
          d: "Өмнөх шалгалтын материал, дасгал, түүхүүд.",
        },
      ],
      t: [
        {
          Бүртгэл:
            "Шалгалтын төвөөс хамаарна (ихэвчлэн 9-10 сарын хооронд). Шалгалт ихэвчлэн 5-6 сарын хооронд зохион байгуулагддаг шалгалтын төвөөс тодорхой болно.",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=rNN2qvcvH_A",
        "https://www.youtube.com/watch?v=8kSrjG7aSz0",
        "https://www.youtube.com/watch?v=lNwaqDCEjzQ&list=PLOpc1nicRAAOf4s7n04LnDOewe-bUl9x4",
      ],
      m: [
        "Cambridge, Edexcel, OCR хичээлийн ном, гарын авлагууд.",
        "Өмнөх жилийн A-Level шалгалтын материалууд.",
        "Khan Academy, Seneca Learning, ZNotes.",
      ],
      s: "Шалгалтын бүтэц: Мэдлэг шалгах хэсэг, олон сонголттой асуулт, богино хариулт. Кейстэй холбоотой асуудал шийдвэрлэх. Лабораторийн туршилт эсвэл хээрийн судалгааны дүн шинжилгээ (байгалийн шинжлэх ухаан, газарзүйн чиглэл).",
      p: "Шалгалтын дүнг A - E* хүртэлх үнэлгээгээр тогтооно. A* хамгийн өндөр оноо, E хамгийн доод оноо.",
      g: "Шалгалтын төв эсвэл олон улсын сургуулийн A-Level шалгалтын албатай холбогдох. Бүртгэлийн маягтыг бөглөх, шаардлагатай баримтуудыг бүрдүүлж төлбөрөө төлөх.",
      r: "https://www.britishcouncil.pk/exam/school/register/school-students",
      f: "Шалгалтын төлбөр нь хичээлээс хамаарч өөрчлөгдөх ба ойролцоогоор $100-$150 хооронд байна.",
      a: [
        "Шалгалтын өмнөх өдөр хангалттай амрах.",
        "Хүчинтэй баримт бичиг, шалгалтад шаардлагатай хэрэгслүүдээ шалгах.",
        "Өөртөө итгэлтэй бай. Итгэлтэй байдал таныг стресст автуулахгүй, илүү төвлөрөхөд тусална.",
      ],
      day: "Шалгалтын дүнг ихэвчлэн 8-р сарын дунд үед зарладаг.",
      i: "A-Level хөтөлбөрт суралцаж буй ахлах ангийн сурагчид. Дахин шалгалт өгөхийг хүссэн оюутнууд.",
      wn: "Их Британи, Австрали, Канад болон бусад орны их сургуульд элсэн суралцахыг хүсэгчдэд. Мэргэжлийн чиг баримжаагаа тодорхойлохыг зорьж буй сурагчдад.",
    },
    {
      img: "../assets/igcse.png",
      n: "IGCSE",
      d: "IGCSE (International General Certificate of Secondary Education) нь 14-16 насныханд зориулсан олон улсын түвшний хичээлийн хөтөлбөр бөгөөд ихэвчлэн ахлах сургуулийн сүүлийн хоёр жилд судалдаг. Энэ нь Английн сургалтын хөтөлбөр дээр суурилсан, дэлхийн хэмжээнд хүлээн зөвшөөрөгдсөн шалгалт бөгөөд сурагчдыг академик мэдлэг, ур чадвараар үнэлдэг.",
      c: [
        "Хичээл бүрийн сурах бичгийг судалж, сургалтын төлөвлөгөө гаргах нь үр дүнтэй.",
        "Жишиг шалгалтын материалууд ажиллаж өөрийн мэдлэгээ бататгах.",
        "Шалгалтын үед цагийн менежментдээ анхаарах хэрэгтэй.",
      ],
      l: [
        "Cambridge-ийн хөтөлбөртэй сургуулиуд болон сургалтын төвүүдэд шалгалт авдаг.",
      ],
      w: [
        {
          n: "Save My Exams",
          w: "https://www.savemyexams.com/igcse/",
          d: "IGCSE шалгалтанд зориулсан материалууд.",
        },
        {
          n: "IGCSE Past Papers",
          w: "https://pastpapers.co/cie/?dir=IGCSE",
          d: "Өмнөх шалгалтын материалууд.",
        },
        {
          n: "BBC Bitesize",
          w: "https://www.bbc.co.uk/bitesize/levels/z98jmp3",
          d: "IGCSE хичээлийн агуулга.",
        },
      ],
      t: [
        {
          Бүртгэл:
            "Шалгалтын бүртгэл нь шалгалтын өдрөөс 3-4 сарын өмнө эхэлдэг.",
          Өдөр: "IGCSE шалгалт жилд хоёр удаа (зун болон намар) зохион байгуулагддаг. Хичээл бүр өөр хуваарьтай",
          Цаг: "Хичээл тус бүрийн шалгалт 1-2 цаг үргэлжилдэг.",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=yeqboqRfE2U",
        "https://www.youtube.com/watch?v=VM8CSYby9SQ",
        "https://www.youtube.com/watch?v=QGE-heDhIMw",
      ],
      m: [
        "Cambridge IGCSE Revision Guide",
        "Collins IGCSE English Language Workbook",
        "Oxford IGCSE Mathematics Textbook",
        "Cambridge IGCSE Science Textbooks",
        "Khan Academy, Save My Exams",
      ],
      s: "Шалгалтын бүтэц: Mathematics (Математик), English Language (Англи хэл), Science (Шинжлэх ухаан), History (Түүх), Geography (Газарзүй), ICT (Мэдээллийн технологи), Economics (Эдийн засаг) зэрэг хичээлээс сонгон шалгалт өгч болно.",
      p: "IGCSE шалгалт нь *A (хамгийн өндөр)**-ээс G (хамгийн бага) хүртэл үнэлэгддэг. C болон түүнээс дээш үнэлгээ нь их сургуулийн элсэлтийн шаардлага хангадаг.",
      g: "Суралцаж буй сургуулиараа дамжуулан эсвэл бие даан Cambridge-ийн албан ёсны сайтаар бүртгүүлнэ. Шалгалт өгөх хичээлээ сонгож, төлбөрийг төлнө.",
      r: "https://www.cambridgeinternational.org/why-choose-us/find-a-cambridge-school/",
      f: "Хичээл тус бүрийн төлбөр ойролцоогоор 500,000 - 800,000 төгрөг.",
      a: [
        "Бүх шаардлагатай бичиг баримтаа шалгалтын өмнө бэлэн байлгаарай.",
        "Сэтгэлзүйгээ тайван байж, битгий сандар.",
        "Цагийн менежмент сайн хийж, бүх асуултадаа хариулахыг хичээгээрэй.",
      ],
      day: "8 сарын 15 олон улс даяар.",
      i: "14-16 насны сурагчид, олон улсын ахлах сургуулийн хөтөлбөрт хамрагдсан сурагчид өгөх боломжтой.",
      wn: "Дэлхийн түвшний академик үнэлгээнд хамрагдахыг хүссэн сурагчид. Гадаадад их сургуулиудад суралцах зорилготой сурагчид, элсэгчид.",
    },
    {
      n: "O-Level",
      d: "O-Level шалгалт нь дэлхий даяар олон улсын болон үндэсний боловсролын тогтолцоонд хүлээн зөвшөөрөгдсөн, 14-16 насныханд зориулагдсан шалгалт юм. Энэ нь ихэнх оронд ахлах ангийн сургалтын түвшинг шалгах зорилготой бөгөөд ихэнх суралцагчид О-Level шалгалтыг математикийн болон шинжлэх ухааны чиглэлээр, мөн гадаад хэл, түүх, газарзүй зэрэг олон салбарын хичээлээр өгдөг.",
      c: [
        "Хичээлээ тогтмол давтах: О-Level шалгалт нь өргөн хүрээтэй, олон сэдвийг хамардаг тул хичээлээ давтах, нэмэлт материал унших, багш болон найзуудтайгаа хэлэлцэх нь чухал.",
        "Шалгалтын хуваарийг урьдчилан судлах: Өмнөх жилүүдийн шалгалтын асуултуудыг үзэж, шалгалт авах өдөр нь ямар сэдвүүдийг голлон асуухыг таамаглах.",
        "Бэлтгэлийн цагийн хуваарь: Шалгалтад бэлдэхдээ цагийн менежментийг анхаарч, бүх сэдвийг тэгш хувиар судлах.",
      ],
      w: [
        {
          n: "BBC Bitesize",
          w: "https://www.bbc.co.uk/bitesize",
          d: "О-Level шалгалтын материалууд.",
        },
        {
          n: "Cambridge International",
          w: "https://www.cambridgeinternational.org/",
          d: "О-Level шалгалт болон бэлтгэлийн материалууд.",
        },
        {
          n: "Save My Exams",
          w: "https://www.savemyexams.com/o-level/",
          d: "О-Level шалгалтанд зориулсан материалууд.",
        },
      ],
      l: [
        "Шалгалтын төвүүд буюу сургуулиуд. Олон улсын шалгалтанд хамрагдах боломжтой.",
      ],
      t: [
        {
          Бүртгэл:
            "Шалгалтын бүртгэл нь жилд 2 удаа байдаг, нэг удаа 10-р сард, нөгөө удаа 4-р сард.",
          Өдөр: "Эдгээр шалгалтууд нь ерөнхийдөө 5-6 сарын хугацаанд болдог, ерөнхий хуваарийн дагуу.",
        },
      ],
      v: [
        "https://www.youtube.com/watch?v=5iHS0s5ROfM&list=PLL4hZ1kpl1foDh0XuzFepJ-NSz90jKmpe",
        "https://www.youtube.com/watch?v=4YpJaVyLzFo",
        "https://www.youtube.com/watch?v=S_TdmHME730&list=PLLyqGZba3Xv0wWd7EifQ4lrGviljHgGzA",
      ],
      m: [
        "Cambridge O-Level сурах гарын авлага",
        "Өмнөх шалгалтууд",
        "Текст ном ба дасгал ажлын ном",
      ],
      s: "Шалгалтын бүтэц: Хичээл тус бүрийн шалгалт: Жишээ нь, Математик, Хими, Биологи, Газарзүй, Англи хэл зэрэг. Зургийн материал, график, карт дээр ажиллах (Зарим хичээлд). Теорем, дүрэм, шалгалтын жишиг даалгавар, эссе бичих (Түүх, хэл зүй, англи хэл гэх мэт).",
      p: "Шалгалтын дүнг A - E* хүртэлх үнэлгээгээр тогтооно. A* хамгийн өндөр оноо, E хамгийн доод оноо.",
      g: "Сургуулиуд эсвэл шалгалтын төвд бүртгүүлнэ.",
      r: "https://www.cambridgeinternational.org/",
      f: "50-150$-ийн хооронд төлбөртэй байдаг, хичээлийн тоо болон шалгалтын төрөлтэй холбоотойгоор ялгаатай байж болно.",
      a: [
        "Тестийн өдрөөс өмнө сайн унтаж амрах.",
        "Бүртгэлийн хуудас, бичгийн хэрэгсэл, зураг төсөл болон шаардлагатай материалуудыг нягтлах.",
        "Өөртөө итгэлтэй бай. Итгэлтэй байдал таныг стресст автуулахгүй, илүү төвлөрөхөд тусална.",
      ],
      day: "Шалгалтын дараа 2-3 сарын дараа хариу гардаг бөгөөд үүний дараа таны оноог албан ёсны бичигт хүргүүлнэ.",
      i: "14-16 насныхан (ихэнхдээ 10-12 ангийн сурагчид).",
      wn: "Дэлхийн түвшний академик үнэлгээнд хамрагдахыг хүссэн сурагчид. Англи, Канад, АНУ, Австрали, Сингапур гэх мэт улсуудын их сургуулиудад өргөдөл илгээх сурагчид.",
    },
    {
      n: "DELF",
      d: "DELF (Diplôme d'Études en Langue Française) Гадаад иргэдийн франц хэлний мэдлэгийг баталгаажуулах зорилгоор Францын Боловсролын яамнаас олгодог албан ёсны диплом. Энэхүү шалгалт нь түвшинээс хамааран өөр байх бөгөөд насан туршид хүчин төгөлдөр байдаг. A1: Анхан шатны харилцааны чадвар; энгийн үг хэллэг, өдөр тутмын үйл ажиллагааны талаар ойлгох, хариулах чадвар. A2: Өдөр тутмын байнгын нөхцөл байдалд амжилттай харилцах чадвар. B1: Бие даасан байдлаар хэл ашиглах чадвар, санаа бодлоо илэрхийлэх, хялбар текст ойлгох чадвар. B2: Нарийн мэргэжлийн болон академик түвшинд Франц хэлийг хэрэглэх чадвар, цогц мэдээлэл ойлгох болон илэрхийлэх чадвар.",
      c: [
        "Түвшнээ тодорхойлох: CEFR түвшний тест өгч, өөрийн мэдлэгийн түвшинг тодорхойлох.",
        "Хичээлийн материал ашиглах: DELF-ийн гарын авлага, онлайн тест, аудио, видео материал ашиглах.",
        "Суралцах стратеги: Сонсох, унших, бичих, ярих 4 чадвараа жигд сайжруулах.",
        "Мэргэжлийн багшаар хичээллэх: Зөвлөгөө авах, дутагдалтай талдаа анхаарах. Улс болгоны Alliance Française төвүүд мэрэгжилийн зөвөлгөө болон хэрэгтэй сурах бичгээр хангаж өгдөг.",
      ],
      w: [
        {
          n: "TV5Monde",
          w: "https://langue-francaise.tv5monde.com",
          d: "Франц хэлийг сурах, шалгалтад бэлдэх материал.",
        },
        {
          n: "Français Facile",
          w: "https://francaisfacile.rfi.fr/fr/",
          d: "Франц хэлний шалгалтын бэлтгэл.",
        },
        {
          n: "Bonjour de France",
          w: "https://bonjourdefrance.com",
          d: "Франц хэлний сургалт.",
        },
        {
          n: "Prep My Future",
          w: "https://prepmyfuture.com/en",
          d: "DELF шалгалтанд зориулсан бэлтгэл.",
        },
        {
          n: "Global Exam",
          w: "https://global-exam.com/en",
          d: "DELF шалгалтын тест.",
        },
        {
          n: "Quizlet",
          w: "https://quizlet.com/latest",
          d: "Франц хэлийг сурах материал.",
        },
        {
          n: "Conjuguemos",
          w: "https://conjuguemos.com",
          d: "Франц хэлний дасгал.",
        },
      ],
      l: [
        "Alliance Française d’Oulan-Bator / Монгол - Францын төв дээр шалгалт өгдөг. Тухайн газраас хуваарь гаргаж өгдөг.",
      ],
      v: ["https://youtu.be/JTCzrSvm_p0", "https://youtu.be/ARbOwNPBGM4"],
      f: "200-300'000₮",
      m: ["DELF activités", "ABC DELF", "Edito"],
      s: "Шалгалтын бүтэц: Сонсох (Compréhension de l'oral): Тодорхой бичлэг эсвэл ярилцлагыг сонсоод асуултад хариулах. Унших (Compréhension des écrits): Текст уншаад ойлгох чадварыг шалгах. Бичих (Production écrite): Тодорхой сэдвээр эссэ, захиа эсвэл тайлбар бичих. Ярих (Production orale): Өөрийгөө танилцуулах, санал бодлоо хуваалцах, эсвэл тодорхой нөхцөл байдлын талаар ярих.",
      p: "Нийт оноо: 100. Тэнцэх оноо: 50 (хэдий ч 4 хэсгийн аль нэгээс 25-аас доош оноо авбал тэнцэхгүй.)",
      g: "Монгол-Франц төвтэй холбогдож хэрэгтэй бичиг баримт, шалгалтын төлбөрийг авчирна.",
      r: "https://www.facebook.com/afobmongolie",
      a: [
        "Бичиг баримтуудаа шалгаж, харандаа баллуур зэрэг шаардлагатай бичиг хэргийн зүйлээ бэлднэ.",
        "Шалгалт авах газраа хоцролгүй, эхлэхээс 30 минутын өмнө ирнэ.",
        "Сайн унтаж амраж, хөнгөн хооллоод ирээрэй.",
        "Зохион байгуулагчийн зааврыг маш сайн сонс.",
        "Аль болох тайван байхыг хичээгээрэй.",
      ],
      day: "Шалгалтын дүнг тухайн газраас мэдэгдэх бөгөөд дипломыг франц улсын элчин сайдаас хүлээн авдаг.",
      i: "Франц болон Канад улс луу явахаар төлөвлөсөн гадаад улсын иргэд.",
      wn: "Франц эсвэл Канад улс руу сурахаар болон цагаачлахаар явах гэж буй хүн бүхэнд.",
    },
    {
      n: "DALF",
      d: "DALF (Diplôme Approfondi de Langue Française) шалгалт нь Франц хэлний ахисан түвшний шалгалт бөгөөд CEFR (Common European Framework of Reference for Languages) системийн B2 түвшнээс дээшхийг баталгаажуулдаг. DELF шалгалтаас ялгаатай нь DALF нь зөвхөн C1 ба C2 түвшний шалгалтуудыг хамардаг. Энэ шалгалтыг амжилттай өгснөөр франц хэлээр ажиллах, суралцах эсвэл амьдрах орчинд ахисан түвшний чадвараа батлах боломжтой. C1: Франц хэлний мэргэжлийн түвшний хэрэглээг батлахад зориулагдсан. Францад бакалаврын дараах түвшний (магистр, доктор) сургалтад элсэн суралцахад шаардлагатай. C2: Франц хэлний хамгийн өндөр түвшний хэрэглээг батлах.",
      c: [
        "Түвшнээ тодорхойлох: CEFR түвшний тест өгч, өөрийн мэдлэгийн түвшинг тодорхойлох.",
        "Хичээлийн материал ашиглах: DELF-ийн гарын авлага, онлайн тест, аудио, видео материал ашиглах.",
        "Суралцах стратеги: Сонсох, унших, бичих, ярих 4 чадвараа жигд сайжруулах.",
        "Мэргэжлийн багшаар хичээллэх: Зөвлөгөө авах, дутагдалтай талдаа анхаарах. Улс болгоны Alliance Française төвүүд мэргэжилийн зөвөлгөө болон хэрэгтэй сурах бичгээр хангаж өгдөг.",
      ],
      w: [
        {
          n: "France Education International",
          w: "https://www.france-education-international.fr",
          d: "Франц хэлний боловсролын шалгалттай холбоотой мэдээлэл.",
        },
        {
          n: "GlobalExam",
          w: "https://global-exam.com",
          d: "DALF шалгалтанд зориулсан онлайн бэлтгэл.",
        },
        {
          n: "TV5Monde",
          w: "https://langue-francaise.tv5monde.com",
          d: "Франц хэлний тест болон аудио материал.",
        },
      ],
      l: [
        "Alliance Française d’Oulan-Bator / Монгол - Францын төв дээр шалгалт өгдөг. Тухайн газраас хуваарь гаргаж өгдөг.",
      ],
      v: ["https://youtu.be/KBP-hnA-9AI", "https://youtu.be/CmX8Xgu4nuo"],
      m: [
        "DALF C1/C2 250 Activités",
        "Objectif DALF C1-C2",
        "Réussir le DALF C1 et C2",
      ],
      s: "Шалгалтын бүтэц: 1. Унших (Compréhension écrite): Текст уншиж, ойлголтыг шалгана. 2. Сонсох (Compréhension orale): Аудио бичлэг сонсоод түүнийг ойлгосон эсэхийг шалгана. 3. Бичих (Production écrite): Зохиомж, санал шүүмжлэл бичих эсвэл гүнзгий анализ хийх. 4. Ярих (Production orale): Сэдэвт анализ хийж ярилцах эсвэл сэдэвчилсэн илтгэл тавих.",
      p: "Шалгалт нь 100 оноо авч болох бөгөөд 50 оноо хүрсэн тохиолдолд тэнцэнэ. Шалгалтын тус бүрийн хэсэгт дор хаяж 5 оноо авах шаардлагатай.",
      g: "Монгол-Франц төвтэй холбогдож хэрэгтэй бичиг баримт, шалгалтын төлбөрийг авчирна.",
      r: "https://www.facebook.com/afobmongolie",
      p: "Түвшин болон авж буй улсаас хамааран шалгалтын төлбөр өөр байж болно.",
      f: "300-400'000₮",
      a: [
        "Бичиг баримтуудаа шалгаж, харандаа баллуур зэрэг шаардлагатай бичиг хэргийн зүйлээ бэлднэ.",
        "Шалгалт авах газраа хоцролгүй, эхлэхээс 30 минутын өмнө ирнэ.",
        "Сайн унтаж амраж, хөнгөн хооллоод ирээрэй.",
        "Зохион байгуулагчийн зааврыг маш сайн сонс.",
        "Аль болох тайван байхыг хичээгээрэй.",
      ],
      day: "Шалгалтын хуваарийг Alliance Française d’Oulan-Bator / Монгол - Францын төвөөс гаргана, шалгалтын дүнг мөн тэндээс мэдэгдэх бөгөөд дипломыг франц улсын элчин сайдаас хүлээн авдаг.",
      i: "Франц хэлний өндөр түвшний мэдлэгтэй хүмүүс. Францын их дээд сургуульд элсэн суралцахыг хүссэн хүмүүс (C1 ба түвшнээс дээш). Францын мэргэжлийн болон академик орчинд ажиллах хүмүүс.",
      wn: "Франц эсвэл албан ёсны хэл нь франц байдаг улс руу сурахаар болон ажиллахаар явах гэж буй хүн бүхэнд.",
    },
    {
      n: "hel-bichgiin-shalgalt",
      d: "Монгол хэл бичгийн шалгалт нь Монгол Улсад их, дээд сургуульд элсэхийн өмнө өгдөг зайлшгүй шаардлагатай шалгалт юм. Энэхүү шалгалт нь сурагчдын эх хэлний мэдлэг, хэрэглээ, унших, бичих чадвар, зөв бичгийн дүрмийн мэдлэгийг шалгах зорилготой. Шалгалт нь үнэ төлбөргүй бөгөөд элсэлтийн ерөнхий шалгалтад бүртгүүлсэн бүх сурагчид үүнийг өгөх ёстой.",
      c: [
        "Үндэсний Бичиг: Үсэгнүүдээ нүдлэх, өдөр бүр тогтмол унших дасгал хийх.",
        "Сурах бичгээс онцгой бичигддэг үгнүүдийг цээжлэх.",
        "Эссэ: Зөв бичих дүрэм дээр анхаарах, жишээ эссэнүүдийг уншиж санаа аваарай.",
        "Эссэ тань өгөддсөн сэдэвтэй таарч байна уу эсэхэд анхаарах.",
        "Эшлэл, баримт дурьдах.",
        "Бичгийн хэлбэр. Төлбөр таарч байна уу эсэхийг шалгах.",
        "Бүтэц найруулга дээрээ анхаараарай.",
        "Үгийн тоондоо болон бичгийн соёл дээрээ мөн анхаараарай.",
        "Хэрэв та бүртгэлээ амжилттай хийсэн эсэхийг шалгахгүй бол цаг хугацаагаа алдах боломжтой.",
      ],
      w: [
        {
          n: "Scribd",
          w: "https://www.scribd.com/document/472926120/эх-хэлний-шалгалтын-жишиг-хэвлэх",
          d: "Эх хэлний шалгалтын жишиг хэвлэх материал.",
        },
      ],
      l: [
        "Бүртгэл хийсний дараа шалгалт өгөх газрыг мэдэгдэнэ. Өөрийн сургууль дээр өгөхгүй харьяа дүүргийн сургууль дээр очиж өгнө.",
      ],
      t: [
        {
          Бүртгэл:
            "Боловсролын үнэлгээний газар мэдээлэл өгнө. ЭЕШ-ын бүртгэлтэй зэрэг болдог.",
          Өдөр: "Боловсролын үнэлгээний газар мэдэгдэнэ.",
          Давтан:
            "8 сарын эхээр явагддаг. Шалгалтын хуваарийг Боловсролын үнэлгээний газар мэдэгдэнэ.",
        },
      ],
      v: ["https://youtu.be/zQlkrRZAxVc?si=-VT_Q_GzTYPCd24q"],
      m: [
        "Монгол хэл бичгийн шалгалтын Туслах багш",
        "Эх хэл бичгийн шалгалтын даалгаварын эмхэтгэл",
      ],
      s: "Шалгалтын бүтэц: 1. Унших чадвар: Өгөгдсөн эхийг уншиж ойлгон асуултанд хариулах. 2. Бичих чадвар: Зөв бичих дүрмийн алдаатай үгийг олж залруулах, үндэсний бичгээс кирилл рүү хөврүүлэн бичих, эссэ бичих.",
      p: "Унших чадвар (20 оноо), Бичих чадвар (30 оноо)",
      g: "Одоогийн төгсөгчид автоматаар бүртгэгддэг, өмнөх оны төгсөгчид өөрсдөө бүртгүүлэх шаардлагатай.",
      r: "www.eec.mn",
      f: "Шалгалтын төлбөр байхгүй.",
      a: [
        "Өөрийн бичиг баримтыг бүрэн эсэхийг шалгаарай.",
        "Шалгалт эхлэхээс цагийн өмнө сургууль дээрээ очиж суудлаа эзлэнэ.",
        "Шалгалтын өмнө сайн амарч, хөнгөн хооллосон байна.",
        "Аль болон шалгалтыг сандарч түгшилгүйгээр тайван өгөөрэй.",
      ],
      day: "Хариу хэзээ гарах: Тухайн жилийн шалгалтын хариуг Боловсролын үнэлгээний газар мэдэгдэнэ. Ойролцоогоор сарын дараа гардаг.",
      i: "ЭЕШ-ын өгөхөөр төлөвлөж буй хүн бүр.",
      wn: "Монголд их сургуульд элсэхээр горилж буй бүх хүмүүс.",
    },
    {
      img: "../assets/gmat.png",
      n: "GMAT",
      d: "GMAT (Graduate Management Admission Test) нь бизнесийн удирдлагын чиглэлээр магистрын зэрэгт суралцах хүсэлтэй хүмүүсийн тоон дүн шинжилгээ, аналитик, уншиж ойлгох, бичих чадварыг үнэлдэг олон улсын шалгалт юм.",
      c: [
        "GMAT нь олон төрлийн чадварыг сорих шалгалт учир гүйцэтгэлийн хэсгүүдийг ялгаж сурах хэрэгтэй.",
        "Бэлдэх төлөвлөгөө гаргахдаа өдөр бүр өөр өөр сэдвээр бэлдэх ба өөрийн сул хэсгийг тодорхойлон тухайн хэсэг дээрээ бага зэрэг илүү цаг гаргаж байх.",
        "Цахим тестүүдийг ашиглах.",
        "Албан ёсны сурах бичиг хэрэглэх.",
        "Ижил зорилготой хүмүүстэй холбоо тогтоох.",
        "Цагийн менежмент маш сайн байх менежментдээ тааруулж тогтмол жишиг даалгавар хийх.",
        "Сурсан мэдлэгээ гүнзгийрүүлж судлах.",
        "Аль болох эртнээс тогтмол хуваарь, зөв системтэйгээр бэлдэж эхлэх.",
        "Монгол улсад шалгалт авах газар ганцхан байдаг тул танхимаар өгөх бол суудлаа эрт захиалах (1-2 сарын өмнө).",
        "Хэрэв сургалтад суух бодолтой байвал Монгол улсын хэмжээнд GMAT шалгалтад бэлддэг сургалт тун цөөхөн тул өөр улсын цахим сургалтуудыг судалж үзэх.",
      ],
      w: [
        {
          n: "MBA.com (албан ёсны хуудас)",
          w: "https://www.mba.com",
          d: "Албан ёсны сайт",
        },
        {
          n: "GMATCLUB.com",
          w: "https://www.gmatclub.com",
          d: "GMAT клубын вэбсайт",
        },
        {
          n: "Manhattan prep",
          w: "https://www.manhattanprep.com",
          d: "Манхэттен бэлдүүлэг",
        },
        {
          n: "Kaplan GMAT",
          w: "https://www.kaptest.com/gmat",
          d: "Каплан GMAT бэлдүүлэг",
        },
        {
          n: "Target Test Prep",
          w: "https://www.targettestprep.com",
          d: "Target Test Prep бэлдүүлэг",
        },
        {
          n: "GMAT experience",
          w: "https://www.gmatexperience.com",
          d: "GMAT туршлага",
        },
      ],
      l: ["Байршил: English Academy of MUST, шалгалт өгөх тухтай газар."],
      t: [
        {
          Бүртгэл: "MBA.com вэбсайтаар бүртгүүлнэ.",
          Төрөл: "Танхимын болон цахим шалгалт.",
          Өдөр: "Өдөр бүр.",
        },
      ],
      v: [
        "https://youtu.be/xO_qm_iC3VU",
        "https://youtu.be/J_h0evJvN2g",
        "https://youtu.be/WYur92NI7YA",
      ],
      m: [
        "The official guide for GMAT review",
        "Manhattan prep GMAT strategy",
        "Kaplan GMAT prep plus (2021)",
      ],
      p: "Шалгалтын нийт үнэлгээ нь 200–800 оноо бөгөөд Quantitative болон Verbal хэсгийн хариултанд үндэслэнэ. AWA болон IR хэсгүүд нь тус тусад нь үнэлэгддэг.",
      s: "Analytical Writing Assessment (AWA): Цаг: 30 минут. Зорилго: Тухайн өгөгдсөн санаа эсвэл үзэл бодлыг шүүмжлэх буюу батлах шаардлагатай. Логик болон үндэслэлтэй аргументуудыг хэрэглэж, бичгийн чадвараа харуулна. Integrated Reasoning (IR): Цаг: 30 минут. 12 асуулттай бөгөөд эдгээр нь дүрслэл, график болон мэдээллийн хамт анализ хийх чадварыг шалгадаг. Quantitative Reasoning (QR): Цаг: 62 минут. 31 тооцоолон бодох асуулттай. Төрөл бүрийн тооцоолол, алгебр болон геометрийн асуудлууд багтдаг. Verbal Reasoning (VR): Цаг: 65 минут. 36 асуулттай бөгөөд уншиж ойлгох, логик шүүлт хийх болон өгүүлбэрийн бүтэц, утга учрыг тодорхойлох чадварыг шалгадаг.",
      p: "Цахим шалгалт-275$, Танхимын шалгалт-250$",
      g: "MBA.com вебсайтыг ашиглан хэрэглэгчийн бүртгэл нээх. Иргэний үнэмлэх дээрх мэдээлэлтэй заавал таарах шаардлагатай. Шалгалт өгөх огноо болон байршлыг сонгох. Шалгалтын төлбөрийг олон улсын карт, дансаар төлөх.",
      r: "https://www.mba.com",
      a: [
        "Шалгалт эхлэхээс 30 минутын өмнө зөөврийн компьютер, цаас харандаа зэргийг бэлдсэн байх.",
        "Цагийн менежмент хэрэгжүүлэх.",
        "Танхимаар шалгалт өгч байгаа тохиолдолд өөрийн иргэний үнэмлэхийг авч очих.",
        "Математик болон үнэлгээний хэсгүүдэд цаасан дээр тэмдэглэл хөтлөх.",
      ],
      day: "Verbal болон Quantitative хэсгүүдийн оноо нь 7-10 хоногийн дотор гарна. AWA болон IR хэсгүүдийн оноо нь тухайн шалгалт өгсөн өдөртөө гарах болно. Цахим шалгалтын хариу 7 хоногийн дотор гарна.",
      i: "Баклаврын зэрэгтэй эсвэл их сургуульд суралцаж буй магистрын түвшинд суралцах хүсэлтэй хүмүүс.",
      wn: "Гадаадад бизнесийн болон менежментийн чиглэлээр элсэн суралцах хүсэлтэй хүмүүс.",
    },
  ];
  // const gmatData = {
  //   n: "GMAT",
  //   d: "GMAT (Graduate Management Admission Test) нь бизнесийн удирдлагын чиглэлээр магистрын зэрэгт суралцах хүсэлтэй хүмүүсийн тоон дүн шинжилгээ, аналитик, уншиж ойлгох, бичих чадварыг үнэлдэг олон улсын шалгалт юм.",
  //   c: [
  //     "GMAT нь олон төрлийн чадварыг сорих шалгалт учир гүйцэтгэлийн хэсгүүдийг ялгаж сурах хэрэгтэй.",
  //     "Бэлдэх төлөвлөгөө гаргахдаа өдөр бүр өөр өөр сэдвээр бэлдэх ба өөрийн сул хэсгийг тодорхойлон тухайн хэсэг дээрээ бага зэрэг илүү цаг гаргаж байх.",
  //     "Цахим тестүүдийг ашиглах.",
  //     "Албан ёсны сурах бичиг хэрэглэх.",
  //     "Ижил зорилготой хүмүүстэй холбоо тогтоох.",
  //     "Цагийн менежмент маш сайн байх менежментдээ тааруулж тогтмол жишиг даалгавар хийх.",
  //     "Сурсан мэдлэгээ гүнзгийрүүлж судлах.",
  //     "Аль болох эртнээс тогтмол хуваарь, зөв системтэйгээр бэлдэж эхлэх.",
  //     "Монгол улсад шалгалт авах газар ганцхан байдаг тул танхимаар өгөх бол суудлаа эрт захиалах (1-2 сарын өмнө).",
  //     "Хэрэв сургалтад суух бодолтой байвал Монгол улсын хэмжээнд GMAT шалгалтад бэлддэг сургалт тун цөөхөн тул өөр улсын цахим сургалтуудыг судалж үзэх.",
  //   ],
  //   w: [
  //     {
  //       n: "MBA.com (албан ёсны хуудас)",
  //       w: "https://www.mba.com",
  //       d: "Албан ёсны сайт",
  //     },
  //     {
  //       n: "GMATCLUB.com",
  //       w: "https://www.gmatclub.com",
  //       d: "GMAT клубын вэбсайт",
  //     },
  //     {
  //       n: "Manhattan prep",
  //       w: "https://www.manhattanprep.com",
  //       d: "Манхэттен бэлдүүлэг",
  //     },
  //     {
  //       n: "Kaplan GMAT",
  //       w: "https://www.kaptest.com/gmat",
  //       d: "Каплан GMAT бэлдүүлэг",
  //     },
  //     {
  //       n: "Target Test Prep",
  //       w: "https://www.targettestprep.com",
  //       d: "Target Test Prep бэлдүүлэг",
  //     },
  //     {
  //       n: "GMAT experience",
  //       w: "https://www.gmatexperience.com",
  //       d: "GMAT туршлага",
  //     },
  //   ],
  //   l: ["Байршил: English Academy of MUST, шалгалт өгөх тухтай газар."],
  //   t: {
  //     Бүртгэл: "MBA.com вэбсайтаар бүртгүүлнэ.",
  //     Төрөл: "Танхимын болон цахим шалгалт.",
  //     Өдөр: "Өдөр бүр.",
  //   },
  //   v: [
  //     "https://youtu.be/xO_qm_iC3VU",
  //     "https://youtu.be/J_h0evJvN2g",
  //     "https://youtu.be/WYur92NI7YA",
  //   ],
  //   m: [
  //     "The official guide for GMAT review",
  //     "Manhattan prep GMAT strategy",
  //     "Kaplan GMAT prep plus (2021)",
  //   ],
  //   p: "Шалгалтын нийт үнэлгээ нь 200–800 оноо бөгөөд Quantitative болон Verbal хэсгийн хариултанд үндэслэнэ. AWA болон IR хэсгүүд нь тус тусад нь үнэлэгддэг.",
  //   s: "Analytical Writing Assessment (AWA): Цаг: 30 минут. Зорилго: Тухайн өгөгдсөн санаа эсвэл үзэл бодлыг шүүмжлэх буюу батлах шаардлагатай. Логик болон үндэслэлтэй аргументуудыг хэрэглэж, бичгийн чадвараа харуулна. Integrated Reasoning (IR): Цаг: 30 минут. 12 асуулттай бөгөөд эдгээр нь дүрслэл, график болон мэдээллийн хамт анализ хийх чадварыг шалгадаг. Quantitative Reasoning (QR): Цаг: 62 минут. 31 тооцоолон бодох асуулттай. Төрөл бүрийн тооцоолол, алгебр болон геометрийн асуудлууд багтдаг. Verbal Reasoning (VR): Цаг: 65 минут. 36 асуулттай бөгөөд уншиж ойлгох, логик шүүлт хийх болон өгүүлбэрийн бүтэц, утга учрыг тодорхойлох чадварыг шалгадаг.",
  //   p: "Цахим шалгалт-275$, Танхимын шалгалт-250$",
  //   g: "MBA.com вебсайтыг ашиглан хэрэглэгчийн бүртгэл нээх. Иргэний үнэмлэх дээрх мэдээлэлтэй заавал таарах шаардлагатай. Шалгалт өгөх огноо болон байршлыг сонгох. Шалгалтын төлбөрийг олон улсын карт, дансаар төлөх.",
  //   r: "https://www.mba.com",
  //   a: [
  //     "Шалгалт эхлэхээс 30 минутын өмнө зөөврийн компьютер, цаас харандаа зэргийг бэлдсэн байх.",
  //     "Цагийн менежмент хэрэгжүүлэх.",
  //     "Танхимаар шалгалт өгч байгаа тохиолдолд өөрийн иргэний үнэмлэхийг авч очих.",
  //     "Математик болон үнэлгээний хэсгүүдэд цаасан дээр тэмдэглэл хөтлөх.",
  //   ],
  //   day: [
  //     "Verbal болон Quantitative хэсгүүдийн оноо нь 7-10 хоногийн дотор гарна.",
  //     "AWA болон IR хэсгүүдийн оноо нь тухайн шалгалт өгсөн өдөртөө гарах болно.",
  //     "Цахим шалгалтын хариу 7 хоногийн дотор гарна.",
  //   ],
  //   i: "Баклаврын зэрэгтэй эсвэл их сургуульд суралцаж буй магистрын түвшинд суралцах хүсэлтэй хүмүүс.",
  //   wn: "Гадаадад бизнесийн болон менежментийн чиглэлээр элсэн суралцах хүсэлтэй хүмүүс.",
  // };
  return (
    <div className="xl:mt-28 mt-20 dark:text-white justify-center items-center flex">
      {data == [] ? (
        <></>
      ) : (
        <div className="mt-28 gap-16 flex flex-col md:w-[80%] text-justify lg:text-lg">
          <div className="flex flex-col items-center justify-center relative">
            {/* <div className="absolute w-full mt16 h-full bg-[#ffffff] flex justify-center items-center z-0"></div> */}
            <div className="flex justify-center items-center absolute lg:mb-56 mb-48 flex-col text-sm lg:text-normal xl:text-lg">
              Шалгалт
              <div className=" border-2 rounded-full border-black dark:border-white opacity-50 px-2">
                {data.n === "hel-bichgiin-shalgalt"
                  ? "Хэл Бичгийн Шалгалт"
                  : data.n === "yesh"
                  ? "ЭЕШ"
                  : data.n}
              </div>
            </div>

            <svg
              className="w-[80%] lg:w-[40%] absolute -z-10"
              viewBox="0 0 500 434"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M78.8264 170.881L0 216.506L79.4583 262.382L105.556 217.178L78.8264 170.881ZM124.507 342L124.507 433.503L203.879 387.678L177.44 342H124.507ZM295.383 387.046L375 433.013V342H321.39L295.383 387.046ZM420.676 262.625L499.507 216.997L420.048 171.121L393.947 216.329L420.676 262.625ZM375 91.5071V0L295.623 45.8282L322.063 91.5071H375ZM204.123 46.4567L124.507 0.490479V91.5071H178.113L204.123 46.4567Z"
                className="dark:fill-white/15 fill-black/10 transition-all duration-500 ease-in"
                style={col ? { fill: col + "40" } : undefined}
              />
            </svg>

            <div className="flex flex-col items-center justify-center">
              {data.img ? (
                <>
                  {data.imgdr ? (
                    <>
                      <img
                        src={data.img}
                        className="w-96 max-h-96 group-hover/item:opacity-0 opacity-100 object-contain h-full transition-all ease-in-out duration-300 p-4 dark:hidden"
                        alt=""
                      />
                      <img
                        src={data.imgdr}
                        className="w-96  max-h-96 group-hover/item:opacity-0 opacity-100 object-contain h-full transition-all ease-in-out duration-300 p-4 hidden dark:block"
                        alt=""
                      />
                    </>
                  ) : (
                    <img
                      src={data.img}
                      className="w-96  max-h-96 group-hover/item:opacity-0 opacity-100 object-contain h-full transition-all ease-in-out duration-300 p-4"
                      alt=""
                    />
                  )}
                  {/* {data.imgdr ? (
                  <img
                    src={data.imgdr}
                    className="-z-10 object-fill w-full blur-3xl group-hover/item:blur-2xl opacity-30 absolute transition-all ease-in-out duration-300"
                    alt=""
                  />
                ) : data.img ? (
                  <img
                    src={data.img}
                    className="-z-10 object-fill w-full blur-3xl group-hover/item:blur-2xl opacity-30 absolute transition-all ease-in-out duration-300"
                    alt=""
                  />
                ) : null} */}
                </>
              ) : (
                <>
                  <div className=" text-5xl dark:text-white text-black text-center items-center flex justify-center group-hover/item:opacity-0 opacity-100 object-contain h-full w-full p-4 ">
                    {data.n === "hel-bichgiin-shalgalt"
                      ? "Хэл Бичгийн Шалгалт"
                      : data.n === "yesh"
                      ? "ЭЕШ"
                      : data.n}
                  </div>
                </>
              )}
              <a target="_blank" rel="noopener noreferrer" href={data.r}>
                {data.r}
              </a>
            </div>
          </div>

          <p className="mt-4 lg:px-8">{data.d}</p>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">Шалгалтын өдөр</div>
            <div>Та албан ёсны сайтаас харж, өдрөө сонгох боломжтой</div>
            <div className="gap-4 flex">
              <div>Компьютер 🔵</div> <div> Цаас 🟠</div>
              <div> Aль аль нь 🟣</div>
            </div>
            <div className="flex flex-wrap w-full xl:w-auto gap-4 justify-center items-center h-96 lg:h-auto overflow-y-scroll">
              {groupByMonthYear &&
                Object.entries(groupByMonthYear).map(
                  ([yearMonth, days], index) => {
                    const [year, month] = yearMonth.split("-");

                    return (
                      <div
                        key={index}
                        className="max-w-96 flex lg:justify-center lg:items-center flex-col lg:w-auto gap-2 lg;gap-4 bg-[#00000005] dark:bg-white/5 rounded-md "
                      >
                        <h2 className="text-sm md:text-base lg:text-lg font-medium text-center lg:my-2">
                          {month}-р сар, {year}
                        </h2>
                        <div>
                          <div className="flex overflow-x-scroll gap-2 items-center w-96 p-2">
                            {days.map((el, index) => (
                              <div
                                key={index}
                                className={`p-2 lg:p-4 rounded-lg shadow-lg opacity-80 hover:opacity-100 text-xs transition-all ease-in hover:ease-out duration-500 delay-150 transform md:text-sm lg:text-base flex flex-col justify-center group/item 
                  ${
                    el.t === "digital" || el.t === "computer"
                      ? "bg-blue-100 border-l-4 border-blue-500"
                      : ""
                  }
                  ${
                    el.t === "paper"
                      ? "bg-red-100 border-l-4 border-red-500"
                      : ""
                  }
                  ${
                    Array.isArray(el.t)
                      ? "bg-purple-100 border-l-4 border-purple-500"
                      : ""
                  }
                `}
                              >
                                <p className="font-semibold text-gray-800">
                                  {el.day.split("-")[2]}
                                </p>
                                <p
                                  className={`text-gray-600 group-hover/item:flex w-0 h-0 lg:group-hover/item:w-64 group-hover/item:w-48 text-xs lg:text-sm transition-all ease-in-out duration-500 transform overflow-hidden flex flex-col ${
                                    el.loc.length === 1
                                      ? "group-hover/item:h-4 lg:group-hover/item:h-6"
                                      : el.loc.length === 2
                                      ? "group-hover/item:h-8 lg:group-hover/item:h-10"
                                      : el.loc.length === 3
                                      ? "group-hover/item:h-11 lg:group-hover/item:h-14"
                                      : "group-hover/item:h-14 lg:group-hover/item:h-16"
                                  }`}
                                >
                                  {Array.isArray(el.loc)
                                    ? el.loc.map((location, index) => (
                                        <span key={index}>
                                          {location}
                                          {index < el.loc.length - 1 && ", "}
                                        </span>
                                      ))
                                    : el.loc}
                                </p>
                                <p
                                  className={`text-gray-600 text-xs transition-all ease-out group-hover/item:ease-in group-hover/item:h-0 h-3 overflow-hidden duration-300 delay-0 transform lg:text-sm" ${
                                    el.t === "digital" || el.t === "computer"
                                      ? "text-blue-700"
                                      : ""
                                  }
                  ${el.t === "paper" ? "text-red-500" : ""}
                  ${Array.isArray(el.t) ? "text-purple-500" : ""}`}
                                >
                                  {el.loc.length + "Б"}
                                  {/* {el.t === "digital" || el.t === "computer"
                                  ? "Комп"
                                  : el.t === "paper"
                                  ? "Цаас"
                                  : Array.isArray(el.t)
                                  ? "К&Ц"
                                  : ""} */}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl lg:text-3xl text-center">
              Хэнд хэрэгтэй болох
            </div>
            <div
              className={`grid gap-4 2xl:justify-between justify-center items-center ${
                Array.isArray(data.wn)
                  ? `grid-cols-${data.wn.length}`
                  : "grid-cols-1"
              }`}
            >
              {Array.isArray(data.wn) &&
                data.wn.map((item, index) => (
                  <div
                    key={index}
                    className="w-full border dark:bg-white/10 bg-black/5 p-2 rounded-lg dark:border-neutral-700"
                  >
                    {item}
                  </div>
                ))}
            </div>
          </div>
          {data.wn}
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">Зөвлөгөө</div>
            <div className="w-full">
              {data.c?.map((el, index) => (
                <div className="w-full dark:bg-white/10 bg-black/5 rounded-lg border mt-2 p-2 text-justify dark:border-neutral-700">
                  {el}
                </div>
              ))}
            </div>
          </div>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="w-full">
              {data.t?.map((el) => {
                return (
                  <div className="gap-2 flex flex-col w-full">
                    {Object.entries(el).map(([key, value]) => (
                      <div className="flex justify-between gap-4">
                        <div
                          style={{
                            backgroundColor: col ? col + "20" : "transparent",
                            color: col || "inherit",
                          }}
                          className="border flex justify-center items-center rounded-lg border-slate-200 uppercase p-4 font-semibold  w-3/6 lg:w-5/12 bg-[#4bab4da8] dark:border-neutral-700"
                        >
                          {key}
                        </div>
                        <div className="border flex justify-center items-center rounded-lg border-slate-200 p-4 w-full dark:border-neutral-700">
                          {value}
                        </div>
                      </div>
                    ))}
                    <div className="flex justify-between gap-4">
                      <div
                        style={
                          col ? { backgroundColor: col + "40" } : undefined
                        }
                        className="border text-center flex justify-center items-center rounded-lg border-slate-200 uppercase p-4 font-semibold w-3/6 lg:w-5/12 bg-[#4bab4da8] dark:border-neutral-700"
                      >
                        Шалгалтын бүтэц
                      </div>
                      <div className="border flex justify-center items-center rounded-lg border-slate-200 p-4 w-full dark:border-neutral-700">
                        {data.s}
                      </div>
                    </div>
                    <div className="flex justify-between gap-4">
                      <div
                        style={
                          col ? { backgroundColor: col + "40" } : undefined
                        }
                        className="border text-center flex justify-center items-center rounded-lg border-slate-200 uppercase p-4 font-semibold  w-3/6 lg:w-5/12 bg-[#4bab4da8] dark:border-neutral-700"
                      >
                        Онооны систем
                      </div>
                      <div className="border flex justify-center items-center rounded-lg border-slate-200 p-4 w-full dark:border-neutral-700">
                        {data.p}
                      </div>
                    </div>
                    <div className="flex justify-between gap-4">
                      <div
                        style={
                          col ? { backgroundColor: col + "40" } : undefined
                        }
                        className="border flex justify-center items-center rounded-lg border-slate-200 uppercase p-4 font-semibold  w-3/6 lg:w-5/12 bg-[#4bab4da8] dark:border-neutral-700"
                      >
                        Бүртгүүлэх
                      </div>
                      <div className="border flex justify-center items-center rounded-lg border-slate-200 p-4 w-full dark:border-neutral-700">
                        {data.g}
                      </div>
                    </div>
                    <div className="flex justify-between gap-4">
                      <div
                        style={
                          col ? { backgroundColor: col + "40" } : undefined
                        }
                        className="border flex justify-center items-center rounded-lg border-slate-200 uppercase p-4 font-semibold  w-3/6 lg:w-5/12 bg-[#4bab4da8] dark:border-neutral-700"
                      >
                        Төлбөр
                      </div>
                      <div className="border flex justify-center items-center rounded-lg border-slate-200 p-4 w-full dark:border-neutral-700">
                        {data.f}
                      </div>
                    </div>
                    <div className="flex justify-between gap-4">
                      <div
                        style={
                          col ? { backgroundColor: col + "40" } : undefined
                        }
                        className="border text-center flex justify-center items-center rounded-lg border-slate-200 uppercase p-4 font-semibold  w-3/6 lg:w-5/12 bg-[#4bab4da8] dark:border-neutral-700"
                      >
                        Хариу (дэлгэрэн.)
                      </div>
                      <div className="border flex justify-center items-center rounded-lg border-slate-200 p-4 w-full dark:border-neutral-700">
                        {data.day}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* <table class=" border-collapse">
            <tbody>
              <tr className="border-2 dark:border-stone-600">
                <td className="p-2">Шалгалтын бүтэц</td>
                <td className="p-2">{data.s}</td>
              </tr>
              <tr className="border-2 dark:border-stone-600">
                <td className="p-2">Онооны систем</td>
                <td className="p-2">{data.p}</td>
              </tr>
              <tr className="border-2 dark:border-stone-600">
                <td className="p-2">Бүртгүүлэх</td>
                <td className="p-2">{data.g}</td>
              </tr>
              <tr className="border-2 dark:border-stone-600">
                <td className="p-2">Төлбөр</td>
                <td className="p-2">{data.f}</td>
              </tr>
              <tr className="border-2 dark:border-stone-600">
                <td className="p-2">Хариу</td>
                <td className="p-2">{data.day}</td>
              </tr>
            </tbody>
          </table> */}
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">Бичлэг</div>
            <div className="flex flex-wrap gap-4 2xl:justify-between justify-center items-center mt-2">
              {data.v?.map((el) => {
                let videoId = "";
                let isValidThumbnail = true; // Flag to check if the thumbnail is valid

                if (el.includes("youtu.be")) {
                  videoId = el.split("youtu.be/")[1].split("?")[0]; // For shortened URLs
                } else if (el.includes("youtube.com")) {
                  if (el.includes("v=")) {
                    videoId = el.split("v=")[1]?.split("&")[0]; // For regular video URLs
                  } else {
                    isValidThumbnail = false; // Not a valid video link for thumbnails
                  }
                } else {
                  isValidThumbnail = false; // Not a recognizable YouTube URL
                }

                if (isValidThumbnail) {
                  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

                  return (
                    <div className="relative w-96 aspect-video rounded-lg overflow-hidden">
                      <a target="_blank" rel="noopener noreferrer" href={el}>
                        <img
                          src={thumbnailUrl}
                          alt="YouTube Thumbnail"
                          className="object-cover h-auto w-full"
                        />
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <div className="relative w-96 aspect-video rounded-lg overflow-hidden dark:bg-white/10 bg-black/10 p-2 text-center">
                      <a
                        target="_blank"
                        className="text-center w-full h-full"
                        rel="noopener noreferrer"
                        href={el}
                      >
                        {el}
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">Туслах сайт</div>
            <div className="flex flex-wrap gap-4 2xl:justify-between justify-center items-center">
              {data.w?.map((resource, index) => (
                <div
                  className="mt-2 w-full bg-black/5 dark:bg-white/10 rounded"
                  key={index}
                >
                  <a
                    href={resource.w}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="flex flex-row justify-between items-center gap-2 bg-black/5 dark:bg-white/10 rounded w-full group/item">
                      <img
                        src={
                          "http://www.google.com/s2/favicons?domain=" +
                          resource.w
                        }
                        className="w-10 p-2"
                        alt=""
                      />
                      <div className="group-hover/item:hidden">
                        {resource.n}
                      </div>
                      <div className="pr-2">
                        <div className="group-hover/item:hidden">холбоос</div>
                        <div className="group-hover/item:block hidden underline">
                          {resource.w}
                        </div>
                      </div>
                    </div>
                  </a>
                  <div className="p-2">{resource.d}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">
              Туслах материал
            </div>
            <div className="flex flex-wrap gap-2 2xl:justify-between justify-center items-center">
              {data.m?.map((el) => {
                return (
                  <div className="p-2 dark:bg-white/10 bg-black/5 rounded">
                    {el}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">
              Шалгалт өгөх өдрийн зөвлөмж
            </div>
            <div className="flex flex-wrap gap-4 2xl:justify-between justify-center items-center">
              {data.a?.map((el) => {
                return (
                  <div className="p-2 dark:bg-white/10 bg-black/5 rounded w-full">
                    {el}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="gap-2 flex flex-col justify-center items-center">
            <div className="text-xl	lg:text-3xl text-center">
              Шалгалт авах байршил
            </div>
            <div className="flex flex-wrap gap-4 2xl:justify-between justify-center items-center">
              {data.l?.map((el) => {
                return (
                  <div className="p-2 dark:bg-white/10 bg-black/5 rounded">
                    {el}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
