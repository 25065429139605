import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";

export const DailyTips = () => {
  const [categories, setCategories] = useState([]);
  const [tips, setTips] = useState([]);
  const [load, setLoad] = useState(false);
  const [load2, setLoad2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const bairshil = location.pathname.split("/")[2];
  const categoryDescriptions = {
    "Extracurricular Activities":
      "Хичээлээс гадуур үйл ажиллагаанд оролцсоноор таны хувийн хөгжлийг нэмэгдүүлж, танилын хүрээгээ тэлэх, ур чадвараа сайжруулах боломжтой. Клуб, спорт, сайн дурын ажил эсвэл дадлага хийснээр таны CV илүү баялаг болно.",
    Motivation:
      "Зорилгодоо хүрэхийн тулд урам зориг, тэвчээрийг хадгалж, амжилт руу яв. Энэ хэсэгт бид таныг урамшуулах олон арга, стратеги, ижил зорилготой хүмүүсийн туршлагыг хуваалцах болно.",
    "Quote, Ишлэл":
      "Таны сэтгэл санааг өргөж, урам зориг өгч чадах хүчирхэг ишлэлүүдийг эндээс олоорой. Сайн дурын хүмүүсийн амжилттай амьдралын талаархи үгс танд итгэл, хүсэл эрмэлзэл төрүүлэх болно.",
    "University Prep":
      "Их сургуульд суралцах бэлтгэлээ хангахад хэрэгтэй бүх зөвлөгөө, мэдээллийг энэ хэсгээс олоорой. Тэтгэлэг авах, шалгалтад бэлдэх, эсээ бичих болон сургуулийн амьдралд хэрхэн бэлтгэх талаар хэрэгтэй зөвлөмжүүдийг эндээс авна.",
    "Асран хамгаалагч":
      "Хүүхдийн асаргаа, харилцааны ур чадвар болон тэдний сэтгэлзүйн дэмжлэгийг хэрхэн үзүүлэх талаар хэрэгтэй зөвлөмжүүдийг эндээс олоорой. Хүүхдийнхээ хэрэгцээг ойлгон, тэднийг хөгжүүлэх арга замыг судлаарай.",
    "Мэдсэн ч болоосой":
      "Энэ хэсэгт та амьдралынхаа алдаануудыг ойлгон, ирээдүйд тэдгээрийг хэрхэн засаж болох талаархи зөвлөмжүүдийг олж мэдэх боломжтой. Өнгөрсөн туршлагаас суралцаж, илүү амжилттай амьдрах арга замыг хайж олоорой.",
    Мэргэжил:
      "Мэргэжлийн чиглэлд өөрийнхөө хөгжлийг хэрхэн дэмжих, ажил мэргэжлээ төлөвлөх, ур чадвараа сайжруулах зөвлөмжүүдийг эндээс олоорой. Мэргэжлээ сонгох, карьерийн зорилгоо тодорхойлох хэрэгтэй бүх мэдээллийг бид танд хүргэнэ.",
    "Сэтгэлзүй, Burn-Out":
      "Сэтгэл санаа болон стрессийн менежментийн тухай мэдлэгийг олж аваарай. Түүхий эдийн стрессийг хэрхэн удирдах, амжилттай ажиллах чадварыг хадгалах зөвлөмжүүдийг энэ хэсгээс авна.",
    "Хэл сурах":
      "Хэл сурахад зориулагдсан хэрэгтэй хэрэгслүүд, аппликейшнүүд, сурах стратегиудыг эндээс олоорой. Хэлний мэдлэгээ хурдан сайжруулах, шинэ үгсийг цээжлэх болон үр дүнтэй сурах арга замыг эндээс судлаарай.",
    Шалгалт:
      "Шалгалтын бэлтгэлийг хэрхэн зөв хийх, анхаарал төвлөрүүлэх, стрессийг хэрхэн удирдах зөвлөмжүүдийг эндээс олж авах боломжтой. Шалгалтын үед зөв арга барилаар бэлтгэж, амжилттай тэнцэхэд хэрэгтэй бүх зөвлөмжүүдийг энэ хэсэгт багтаасан.",
  };
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "https://tmull-be.vercel.app/tipscategories"
      );
      setCategories(response?.data?.categories);
      setLoad2(true);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  // Fetch tips for a specific category and page
  const fetchTips = async (category, page = 1) => {
    setLoad(false);
    try {
      const response = await axios.get(
        `https://tmull-be.vercel.app/tipscategories/${category}?page=${page}&limit=8`
      );
      setTips(response?.data?.tips);
      setTotalPages(response?.data?.totalPages); // Assuming the server returns totalPages
      setLoad(true);
    } catch (error) {
      console.error("Error fetching tips", error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchTips(bairshil, currentPage);
  }, [bairshil, currentPage]);

  const like = async (tipId) => {
    if (localStorage.getItem("user")) {
      await axios.post(`https://tmull-be.vercel.app/tip/${tipId}/like`, {
        userId: localStorage.getItem("user"),
      });
      fetchTips(bairshil, currentPage); // Refetch tips after liking
    }
  };

  const dislike = async (tipId) => {
    if (localStorage.getItem("user")) {
      await axios.post(`https://tmull-be.vercel.app/tip/${tipId}/dislike`, {
        userId: localStorage.getItem("user"),
      });
      fetchTips(bairshil, currentPage); // Refetch tips after disliking
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchTips(bairshil, newPage); // Fetch tips for the new page
  };

  return (
    <div className="mt-24 lg:mt-28 dark:text-white">
      <div className="text-2xl font-semibold text-center pb-8 w-full">
        {decodeURIComponent(bairshil)}
      </div>

      {load ? (
        <div className="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full">
          {tips.map((tip, index) => (
            <div
              className="rounded-lg p-4 border-2 group/item hover:border-[#4BAB4D] hover:bg-white/15 drop-shadow-sm bg-white/10 dark:border-white/20 cursor-pointer flex flex-row gap-2"
              key={index}
            >
              <div className="w-22 text-nowrap">
                <div
                  className="flex justify-end"
                  style={{
                    color: tip.like.includes(localStorage.getItem("user"))
                      ? "green"
                      : "none",
                  }}
                  onClick={() => like(tip._id)}
                >
                  {tip.like.length} ↑
                </div>
                <div
                  className="flex justify-end"
                  style={{
                    color: tip.dislike.includes(localStorage.getItem("user"))
                      ? "red"
                      : "none",
                  }}
                  onClick={() => dislike(tip._id)}
                >
                  {tip.dislike.length} ↓
                </div>
              </div>
              <div className="flex flex-col justify-between w-full">
                <div className="font-semibold">{tip.content}</div>
                <div className="opacity-60 text-sm flex justify-between">
                  <div>{tip.category}</div> <div>Tmull</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full">
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer flex flex-row gap-2 dark:invert">
            <div className="w-22 text-nowrap text-black/10">
              <div className="flex justify-end">0 ↑</div>
              <div className="flex justify-end">0 ↓</div>
            </div>
            <div className="flex flex-col justify-between w-full">
              <div className="w-full h-8 bg-black/10 rounded-lg font-semibold"></div>
              <div className="opacity-60 text-sm flex justify-between mt-2">
                <div className="w-3/5 h-4 bg-black/10 rounded-lg"></div>{" "}
                <div className="w-1/5 h-4 bg-black/10 rounded-lg"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Pagination Controls */}

      <nav className="flex w-full justify-center items-center gap-x-1 mb-8">
        <button
          type="button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
        >
          <svg
            className="flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m15 18-6-6 6-6"></path>
          </svg>
          <span>Өмнөх</span>
        </button>

        <div className="flex items-center gap-x-1">
          {pageNumbers.map((number) => (
            <button
              key={number}
              disabled={currentPage === number}
              onClick={() => handlePageChange(number)}
              className={`min-h-[38px] min-w-[38px] flex justify-center items-center py-2 px-3 text-sm rounded-lg focus:outline-none disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10 ${
                currentPage === number
                  ? "bg-gray-200 text-gray-800 dark:bg-neutral-600 dark:text-white"
                  : "text-gray-800 hover:bg-gray-100 dark:hover:bg-white/10"
              }`}
            >
              {number}
            </button>
          ))}
        </div>

        <button
          type="button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
        >
          <span>Дараагийх</span>
          <svg
            className="flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="m9 18 6-6-6-6"></path>
          </svg>
        </button>
      </nav>

      <div className="text-2xl font-semibold text-center pb-4 w-full">
        Өдөр тутмын зөвлөгөөний ангилал
      </div>

      {load2 ? (
        <div className="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full">
          {categories.map((category, index) => {
            const description =
              categoryDescriptions[category] ||
              "Энэ категориор тодорхой мэдээлэл байхгүй байна."; // Default description

            return (
              <Link
                className="rounded-lg p-4 border-2 group/item hover:border-[#4BAB4D] hover:bg-[#4BAB4D10] bg-black/5 drop-shadow-sm dark:bg-white/10 cursor-pointer dark:border-white/20"
                key={index}
                to={"/zuvluguu/" + category}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <h3 className="group-hover/item:text-[#4BAB4D] font-semibold">
                  {category}
                </h3>
                <p className="opacity-60 text-sm">{description}</p>
              </Link>
            );
          })}
        </div>
      ) : (
        <div className="grid gap-6 mb-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full">
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer dark:invert">
            <h3 className="group-hover/item:text-[#4BAB4D] font-semibold">
              <div className="w-3/5 h-5 bg-black/10 rounded-lg"></div>
            </h3>
            <p className="opacity-60 text-sm mt-2">
              <div className="w-full h-20 bg-black/10 rounded-lg"></div>
            </p>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer dark:invert">
            <h3 className="group-hover/item:text-[#4BAB4D] font-semibold">
              <div className="w-3/5 h-5 bg-black/10 rounded-lg"></div>
            </h3>
            <p className="opacity-60 text-sm mt-2">
              <div className="w-full h-20 bg-black/10 rounded-lg"></div>
            </p>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer dark:invert">
            <h3 className="group-hover/item:text-[#4BAB4D] font-semibold">
              <div className="w-3/5 h-5 bg-black/10 rounded-lg"></div>
            </h3>
            <p className="opacity-60 text-sm mt-2">
              <div className="w-full h-20 bg-black/10 rounded-lg"></div>
            </p>
          </div>
          <div className="rounded-lg p-4 border-2 group/item bg-black/5 drop-shadow-sm cursor-pointer dark:invert">
            <h3 className="group-hover/item:text-[#4BAB4D] font-semibold">
              <div className="w-3/5 h-5 bg-black/10 rounded-lg"></div>
            </h3>
            <p className="opacity-60 text-sm mt-2">
              <div className="w-full h-20 bg-black/10 rounded-lg"></div>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
