import React from "react";
import { useState, useEffect, useRef } from "react";
import { formatDistanceToNowStrict } from "date-fns";
import axios from "axios";
import { mn } from "date-fns/locale";
const Comment = ({ id }) => {
  const [ar, setAr] = useState(true);
  const [data, setData] = useState([]);
  const [commenti, setCommenti] = useState([]);

  const dataRetriever = async () => {
    await axios
      .get(`https://tmull-be.vercel.app/comment/${id}`)
      .then((response) => {
        console.log(response?.data);
        setData(response?.data);
      });
  };
  useEffect(() => {
    console.log(localStorage.getItem("user"));
    document.title = "Tmull ・ Мэдээ";

    dataRetriever();
  }, []);
  const dataRetriever2 = async () => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Comment`,
        method: "POST",
        data: {
          content: commenti,
          user: localStorage.getItem("user"),
          postId: id,
        },
      }).then((response) => {
        document.getElementById("F").value = "";
        dataRetriever();
      });
    } else {
      window.location.href = "/nevtreh";
    }
  };
  const like = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Comments/${cId}/like`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    } else {
      window.location.href = "/";
    }
  };
  const dlike = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Comments/${cId}/dlike`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    } else {
      window.location.href = "/";
    }
  };
  const [visibleIndexes, setVisibleIndexes] = useState([]);

  const toggleVisibility = (index) => {
    setVisibleIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };
  return (
    <div className="mt-4 flex justify-center flex-col w-full items-center">
      <div className="flex justify-start w-full">
        <div className="text-xl lg:text-2xl font-medium">
          Сэтгэгдэл {data.length ? "(" + data.length + ")" : null}
        </div>
      </div>
      <div className="gap-4 flex flex-col w-full">
        <div className="w-full">
          <div className="relative div">
            <textarea
              id="F"
              rows="4"
              placeholder=" "
              onChange={(e) => setCommenti(e.target.value)}
              className="dark:bg-stone-800 rounded-md shadow-sm border text-sm outline-none ring-0 dark:border-none focus:ring-1 ring-black dark:ring-neutral-600 input w-full max-h-44 lg:max-h-72 min-h-16"
            ></textarea>
            <label
              for="Feedback"
              className="bg-white dark:bg-stone-800 px-1 rounded-md text-sm md:text-base"
            >
              Сэтгэгдэл
            </label>
          </div>

          <button
            onClick={dataRetriever2}
            disabled={commenti.length === 0}
            className={`transition ease-in-out duration-150 px-2 text-sm bg-black/10 rounded-lg dark:text-white py-2 dark:bg-white/10 hover:text-white ${
              commenti.length === 0
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-[#4BAB4D] dark:hover:bg-[#4BAB4D]"
            }`}
          >
            Сэтгэгдлээ нийтлэх
          </button>
        </div>
        <div className="w-full gap-8 flex flex-col overflow-y-auto">
          {data.map((el, index) => {
            return (
              <div className="flex w-full gap-2 lg:gap-4 dark:border-white/5 shadow-sm p-2 lg:p-4 border rounded-md">
                <div className="bg-black/5 rounded-md text-nowrap relative lg:px-2 text-sm lg:text-base xl:text-lg px-1 flex justify-center items-center flex-col">
                  <div
                    className="flex justify-end cursor-pointer"
                    style={{
                      color: el.like.includes(localStorage.getItem("user"))
                        ? "green"
                        : "none",
                    }}
                    onClick={() => like(el._id)}
                  >
                    {el.like.length} ↑
                  </div>
                  <div
                    className="flex justify-end cursor-pointer font-normal"
                    style={{
                      color: el.dlike.includes(localStorage.getItem("user"))
                        ? "red"
                        : "none",
                    }}
                    onClick={() => dlike(el._id)}
                  >
                    {el.dlike.length} ↓
                  </div>
                </div>

                <div className="w-full flex flex-col gap-1.5 lg:gap-2">
                  <div className="flex flex-row items-start gap-1 lg:gap-1.5">
                    <div className="flex items-center justify-center h-full">
                      <svg
                        className="h-6"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                          className="fill-black/85 dark:fill-white/85"
                        />
                      </svg>
                    </div>

                    <div className="flex flex-col justify-between items-center h-full">
                      <div className="flex flex-row items-center justify-center gap-2 text-xs lg:text-sm xl:text-base">
                        <div className="font-medium">{el.author.username}</div>
                        <div className=" text-zinc-700 dark:text-zinc-300">
                          <div>
                            {formatDistanceToNowStrict(new Date(el.cAt), {
                              addSuffix: true,
                              locale: mn,
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-85 text-xs lg:text-sm xl:text-base">
                    {el.content}
                  </div>
                </div>
                <div key={index} className="flex flex-col items-center">
                  {visibleIndexes.includes(index) && (
                    <div className="absolute mt-10 z-10 flex flex-col items-center text-sm font-normal dark:text-white transition-all duration-300 shadow-xs opacity-100 ">
                      <div className="bg-white/60 rounded-lg dark:bg-black/60 border dark:border-white/15">
                        <div
                          style={{
                            display: el.author._id.includes(
                              localStorage.getItem("user")
                            )
                              ? "block"
                              : "none",
                          }}
                          className="py-1.5 cursor-pointer px-2.5 hover:bg-black/5 dark:hover:bg-white/5"
                        >
                          Засах
                        </div>
                        <div
                          style={{
                            display: el.author._id.includes(
                              localStorage.getItem("user")
                            )
                              ? "block"
                              : "none",
                          }}
                          className="py-1.5 cursor-pointer px-2.5 hover:bg-black/5 dark:hover:bg-white/5"
                        >
                          Устгах
                        </div>
                        <div
                          style={{
                            display: el.author._id.includes(
                              localStorage.getItem("user")
                            )
                              ? "none"
                              : "block",
                          }}
                          className="py-1.5 cursor-pointer px-2.5 hover:bg-black/5 dark:hover:bg-white/5"
                        >
                          Report{" "}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    onClick={() => toggleVisibility(index)}
                    className="hover:bg-black/5 w-8 h-8 flex justify-center items-center rounded p-1 cursor-pointer dark:hover:bg-white/5 transition-all duration-300"
                  >
                    <svg
                      width="16"
                      height="3"
                      viewBox="0 0 16 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_519_2986)">
                        <path
                          d="M2 0C2.39782 0 2.77936 0.158035 3.06066 0.43934C3.34196 0.720644 3.5 1.10218 3.5 1.5C3.5 1.89782 3.34196 2.27936 3.06066 2.56066C2.77936 2.84196 2.39782 3 2 3C1.60218 3 1.22064 2.84196 0.93934 2.56066C0.658035 2.27936 0.5 1.89782 0.5 1.5C0.5 1.10218 0.658035 0.720644 0.93934 0.43934C1.22064 0.158035 1.60218 0 2 0ZM8.041 0C8.43883 0 8.82036 0.158035 9.10166 0.43934C9.38296 0.720644 9.541 1.10218 9.541 1.5C9.541 1.89782 9.38296 2.27936 9.10166 2.56066C8.82036 2.84196 8.43883 3 8.041 3C7.64318 3 7.26164 2.84196 6.98034 2.56066C6.69904 2.27936 6.541 1.89782 6.541 1.5C6.541 1.10218 6.69904 0.720644 6.98034 0.43934C7.26164 0.158035 7.64318 0 8.041 0ZM14 0C14.3978 0 14.7794 0.158035 15.0607 0.43934C15.342 0.720644 15.5 1.10218 15.5 1.5C15.5 1.89782 15.342 2.27936 15.0607 2.56066C14.7794 2.84196 14.3978 3 14 3C13.6022 3 13.2206 2.84196 12.9393 2.56066C12.658 2.27936 12.5 1.89782 12.5 1.5C12.5 1.10218 12.658 0.720644 12.9393 0.43934C13.2206 0.158035 13.6022 0 14 0Z"
                          className="fill-black/75 dark:fill-white/75"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_519_2986">
                          <rect width="16" height="3" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Comment;
